/*jshint esversion: 6 */

import GBUIHexagonalFixedButton from './gb-ui-hexagonal-fixed-button';
import GBUIDialog from './../components/gb-ui-dialog';
import GBUISnackbar from './gb-ui-snackbar';

export default class GBUISuggestEdit extends GBUIHexagonalFixedButton {

	constructor(objButtonElement, strUrl) {

		super(objButtonElement);

		let that = this;

		this.arrFormControls = [
			'text',
		];
		this.objSnackbar = GBUISnackbar;
		this.objButtonElement = objButtonElement;
		this.strUrl = strUrl;

		this.objDialogElement = document.getElementById('suggest-edit-dialog');

		if (this.objDialogElement) {

			this.objDialog = new GBUIDialog(that.objDialogElement, that.arrFormControls, () => {

				// Send the info
				// 
				let objFormData = {
					'url': window.location.href
				};

				for(const sFormControlName of that.arrFormControls) {

					const value = that.getValue(that.objDialogElement, sFormControlName);
					if (value) {

						objFormData[sFormControlName] = value;

					}
				}

				that.makeRequest(that.strUrl, 'POST', objFormData, function(arrData, objErrors) {

					if (objErrors===null) {

						if (that.objSnackbar) {

							that.objSnackbar.open('Thank you for your help.');

						}

						that.objDialog.close();
						
					} else {

						// Have some errors
						// if (that.objSnackbar) {
							
						// 	that.objSnackbar.open('An error occured. Please try again later.');

						// }

						that.objDialog.showErrors(objErrors);

					}
				});
			});
		}

		this.initEvents();

	}

	initEvents() {

		let that = this;

		this.objButtonElement.addEventListener('click', objEvent => {

			if (that.objDialog !== undefined) {

				for(const sFormControlName of that.arrFormControls) {

					that.setValue(that.objDialogElement, sFormControlName);

				}

				that.objDialog.open();

			}

			return false;

		});

	}
}