/*jshint esversion: 6 */
import GBUIBase from './../gb-ui-base';

export default class GBUIInfoDataGallery extends GBUIBase {

	constructor(containerElement) {

		super();

		this.containerElement = containerElement;
		this.gallery = null;

		this.initEvents();

	}

	convertDOMtoPSWP() {

		let res = [];

		const arrElements = this.containerElement.querySelectorAll('.gb-info__side-media figure a[data-sizes]');
		for(const element of arrElements) {

			const sizes = element.getAttribute('data-sizes');
			if (!sizes) {

				continue;

			}

			let img = {
				'url': element.href
			};

			const objImg = element.querySelector('.gb-info__side-media__image');
			if (objImg) {
			
				if (objImg.src) {

					img.msrc = objImg.src;

				}
			}

			const arrSizes = sizes.split(',');
			
			for(const size of arrSizes) {

				const prefixAttr = 'data-size-'+ size +'-';

				let item = {};
				item.src = element.getAttribute(prefixAttr + 'url');
				item.w = element.getAttribute(prefixAttr + 'width');
				item.h = element.getAttribute(prefixAttr + 'height');

				if (!item.src || !item.w || !item.h) {

					continue;

				}

				img[size] = item;

			}

			res.push(img);

		}

		return res;

	}

	createGallery(objElement) {
		
		let that = this;

		const PhotoSwipe = require('photoswipe');
		const PhotoSwipeUI = require('./../libraries/pswp_ui.js');
		const objPswpElement = document.getElementById('pswp');

		if (!objPswpElement) {

			return;

		}

		const arrElements = this.containerElement.querySelectorAll('.gb-info__side-media figure a[data-sizes]');

		const options = {
			'index': Array.from(arrElements).indexOf(objElement),
			'showHideOpacity':false,
			'history': false,
			// 'tapToToggleControls': false,
			'getThumbBoundsFn': function(index) {

				// find thumbnail element
				const thumbnail = arrElements[index];

				// get window scroll Y
				const pageYScroll = window.pageYOffset || document.documentElement.scrollTop; 
				// optionally get horizontal scroll

				// get position of element relative to viewport
				const rect = thumbnail.getBoundingClientRect(); 

				// w = width
				return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};

				// Good guide on how to get element coordinates:
				// http://javascript.info/tutorial/coordinates
			}
		};

		const items = this.convertDOMtoPSWP();

		global.pushStateEmitters = global.pushStateEmitters || {};
		global.pushStateEmitters.gallery = this.gallery = new PhotoSwipe(objPswpElement, PhotoSwipeUI, items, options);

		let realViewportWidth,
			preferredSize = 'thumb',
			firstResize = true,
			imageSrcWillChange;

		// beforeResize event fires each time size of gallery viewport updates
		this.gallery.listen('beforeResize', function() {
			// that.gallery.viewportSize.x - width of PhotoSwipe viewport
			// that.gallery.viewportSize.y - height of PhotoSwipe viewport
			// window.devicePixelRatio - ratio between physical pixels and device independent pixels (Number)
			//                          1 (regular display), 2 (@2x, retina) ...

			// calculate real pixels when size changes
			realViewportWidth = that.gallery.viewportSize.x * window.devicePixelRatio;

			// Code below is needed if you want image to switch dynamically on window.resize

			// Find out if current images need to be changed
			if(preferredSize !== 'thumb' && realViewportWidth <= 640) {
			
				preferredSize = 'thumb';
				imageSrcWillChange = true;
			
			} else if(preferredSize !== 'medium' && realViewportWidth > 640 && realViewportWidth <= 1280) {
				
				preferredSize = 'medium';
				imageSrcWillChange = true;
			
			} else if(preferredSize !== 'large' && realViewportWidth > 1280 && realViewportWidth <= 4096) {
			
				preferredSize = 'large';
				imageSrcWillChange = true;

			} else if(preferredSize !== 'source' && realViewportWidth > 4096) {
			
				preferredSize = 'source';
				imageSrcWillChange = true;
			
			}

			// Invalidate items only when source is changed and when it's not the first update
			if(imageSrcWillChange && !firstResize) {
				// invalidateCurrItems sets a flag on slides that are in DOM,
				// which will force update of content (image) on window.resize.
				that.gallery.invalidateCurrItems();
			}

			if(firstResize) {
				firstResize = false;
			}

			imageSrcWillChange = false;

		});

		this.gallery.listen('initialZoomIn', function() {

			const objButton = objPswpElement.querySelector('.pswp__button--zoom');
			if (objButton) {

				objButton.innerText = 'zoom_in';

			}
		});

		this.gallery.listen('initialZoomOut', function() {
			
			const objButton = objPswpElement.querySelector('.pswp__button--zoom');
			if (objButton) {

				objButton.innerText = 'zoom_out';

			}
		});

		this.gallery.listen('destroy', function() {

			if (global.pushStateEmitters !== undefined && global.pushStateEmitters.gallery !== undefined) {

				delete global.pushStateEmitters.gallery;

			}
		});

		// gettingData event fires each time PhotoSwipe retrieves image source & size
		this.gallery.listen('gettingData', function(index, item) {

			if (that.objHasProperty(item, preferredSize)) {

				item.src = item[preferredSize].src;
				item.w = item[preferredSize].w;
				item.h = item[preferredSize].h;
				return;

			}

			for(const s of ['thumb', 'medium', 'large', 'source']) {

				if (that.objHasProperty(item, s)) {

					item.src = item[s].src;
					item.w = item[s].w;
					item.h = item[s].h;
					return;

				}
			}

			// It doesn't really matter what will you do here, 
			// as long as item.src, item.w and item.h have valid values.
			// 
			// Just avoid http requests in this listener, as it fires quite often

		});

		this.gallery.init();
	}

	onImageClick(ev) {

		ev = ev || window.event;

		const pushStateSupport = ('pushState' in history);
		if (!pushStateSupport) {

			return true;

		}

		this.createGallery(ev.currentTarget)

		ev.preventDefault ? ev.preventDefault() : ev.returnValue = false;

		return false;

	}

	initEvents() {

		const arrListElements = this.containerElement.querySelectorAll('.gb-info__side-media');
		if (arrListElements) {

			for (const objListElement of arrListElements) {

				this.initElementEvents(objListElement);

			}
		}
	}

	initElementEvents(objElem) {

		let that = this;

		const objA = objElem.querySelector('a');
		if (objA) {

			objA.addEventListener('click', ev => that.onImageClick(ev));

		}
	}
}
