/*jshint esversion: 6 */

import GbUiCartBase from "../components/gb-ui-cart-base";
import GBUIRemoveConfirmation from './../components/gb-ui-remove-confirmation';
import GBUIMainMenuCartIcon from './../components/gb-ui-main-menu-cart-icon';
import {MDCMenu} from '@material/menu';

export default class GBUICart extends GbUiCartBase {

    constructor(objCartElement, shoppingListUrl, shoppingListId, arrUrls) {

        super(arrUrls);

        this.objCartElement = objCartElement;
        this.objEmptyListPlaceholder = objCartElement.querySelector('[data-ui-role="empty-list-placeholder"]');
        this.objCheckoutPlaceholder = objCartElement.querySelector('[data-ui-role="checkout-placeholder"]');
        this.shoppingListUrl = shoppingListUrl || "cart";
        this.shoppingListId = shoppingListId || 0;
        this.objCartIcon = GBUIMainMenuCartIcon;
        this.arrUrls = arrUrls;

        this.initEvents();

    }

    listItemActionLoading(objRow) {

        objRow.classList.add('gb-shopping-list__row--loading');

        const arrControls = objRow.querySelectorAll('input, button');
        for(const objControl of arrControls) {
            objControl.setAttribute('disabled', 'disabled');
        }

        const progressElementWrapper = document.createElement('div');
        progressElementWrapper.classList.add('gb-shopping-list__row__loading-indicator');

        const progressElement = this.createCircularProgress('indeterminate');
        progressElementWrapper.appendChild(progressElement);

        objRow.appendChild(progressElementWrapper);

    }

    listItemActionLoadingFinished(objRow) {

        objRow.classList.remove('gb-shopping-list__row--loading');

        const objLoadingIndicator = objRow.querySelector('.gb-shopping-list__row__loading-indicator');
        objRow.removeChild(objLoadingIndicator);

        const arrControls = objRow.querySelectorAll('input, button');
        for(const objControl of arrControls) {
            objControl.removeAttribute('disabled');
        }
    }

    getApiRowUrl(rowId) {

        return this.arrUrls.apiShoppingListRow.replace('%shopping_list%', this.shoppingListUrl).replace('%row%', rowId);

    }

    getRowId(objRowControls) {

        const objRowId = objRowControls.querySelector('[data-ui-role="row-id"]');
        if (!objRowId) return false;

        const rowId = parseInt(objRowId.value);
        if (!rowId) return false;

        return rowId;

    }

    onDeleteButtonClick(ev) {

        ev.preventDefault();

        let that = this;

        const objRowControls = ev.currentTarget.parentElement;
        if (!objRowControls) return false;

        const rowId = this.getRowId(objRowControls);
        if (!rowId) return false;

        const objRow = this.objCartElement.querySelector('.gb-shopping-list__row[data-id="' + rowId + '"]')
        if (!objRow) return false;

        const objRemoveDialog = new GBUIRemoveConfirmation('item', () => {

            that.listItemActionLoading(objRow);

            that.makeRequest(that.getApiRowUrl(rowId), 'DELETE', null, function (arrData, objErrors) {

                if (objErrors === null) {

                    const rowParent = objRow.parentNode;
                    rowParent.removeChild(objRow);
                    const listIsEmpty = null === rowParent.querySelector('.gb-shopping-list__row[data-id]');

                    /**
                     * If list is empty shop a placeholder
                     */
                    if (listIsEmpty) {

                        that.objEmptyListPlaceholder.classList.remove('hidden');
                        if (that.objCheckoutPlaceholder) that.objCheckoutPlaceholder.classList.add('hidden');

                        /**
                         * If we are in our shopping cart and it became empty
                         */
                        if (that.shoppingListId === 0) {
                            that.objCartIcon.empty();
                        }
                    }

                } else {

                    that.listItemActionLoadingFinished(objRow);
                    that.objSnackbar.open("An error occurred. Please try again later.");

                }
            });
        });

        objRemoveDialog.open();

        return false;

    }

    moveToShoppingList(objRow, rowId, newShoppingListId, newShoppingListUrl, newShoppingListName = null) {

        let that = this;

        // Send the info
        //
        let objFormData = {
            shopping_list_id: newShoppingListId,
        };

        that.listItemActionLoading(objRow);

        that.makeRequest(that.getApiRowUrl(rowId), 'POST', objFormData, function (arrData, objErrors) {

            if (objErrors === null) {

                const rowParent = objRow.parentNode;
                rowParent.removeChild(objRow);
                const listIsEmpty = null === rowParent.querySelector('.gb-shopping-list__row[data-id]');

                if (newShoppingListUrl === 'cart') {

                    that.objCartIcon.full();

                    /**
                     * If list is empty shop a placeholder
                     */
                    if (listIsEmpty) {

                        that.objEmptyListPlaceholder.classList.remove('hidden');
                        if (that.objCheckoutPlaceholder) that.objCheckoutPlaceholder.classList.add('hidden');

                    }

                    that.objSnackbar.open("Item has been moved to your cart.", "Visit your cart", () => {
                        window.location = that.arrUrls.cart;
                    });

                } else {

                    /**
                     * If list is empty shop a placeholder
                     */
                    if (listIsEmpty) {

                        that.objEmptyListPlaceholder.classList.remove('hidden');
                        if (that.objCheckoutPlaceholder) that.objCheckoutPlaceholder.classList.add('hidden');

                        /**
                         * If we are in our shopping cart and it became empty
                         */
                        if (that.shoppingListId === 0) {
                            that.objCartIcon.empty();
                        }
                    }

                    that.objSnackbar.open("Item has been moved to shopping list „" + newShoppingListName + "“.", "Visit shopping list", () => {
                        window.location = that.getShoppingListUrl(newShoppingListUrl);
                    });

                }

            } else {

                that.listItemActionLoadingFinished(objRow);
                that.objSnackbar.open("An error occurred. Please try again later.");

            }
        });
    }

    onQtyChange(ev) {

        let that = this;

        const objRowControls = ev.target && ev.target.parentElement && ev.target.parentElement.parentElement ? ev.target.parentElement.parentElement : null;
        if (!objRowControls) return false;

        const rowId = this.getRowId(objRowControls);
        if (!rowId) return false;

        const objRow = this.objCartElement.querySelector('.gb-shopping-list__row[data-id="' + rowId + '"]')
        if (!objRow) return false;

        const qty = ev.target.value;

        // Send the info
        let objFormData = {
            qty: qty,
        };

        that.listItemActionLoading(objRow);

        that.makeRequest(that.getApiRowUrl(rowId), 'POST', objFormData, function (arrData, objErrors) {

            if (objErrors !== null) {

                that.objSnackbar.open("An error occurred. Please try again later.");

            }

            that.listItemActionLoadingFinished(objRow);

        });
    }

    onMoveToButtonClick(ev) {

        ev.preventDefault();

        let that = this;

        const objShoppingListMenu = this.objCartElement.querySelector('[data-ui-role="shopping-list-menu"]');
        if (!objShoppingListMenu) return false;

        this.disableCurrentListInMenu(objShoppingListMenu, this.shoppingListId);

        const objRowControls = ev.currentTarget.parentElement;
        if (!objRowControls) return false;

        const rowId = this.getRowId(objRowControls);
        if (!rowId) return false;

        const objRow = this.objCartElement.querySelector('.gb-shopping-list__row[data-id="' + rowId + '"]')
        if (!objRow) return false;

        const menu = new MDCMenu(objShoppingListMenu);
        if (!menu) {

            return;

        }

        const menuItemClickListener = (ev) => {

            if (
                !this.objHasProperty(ev, 'detail') ||
                !this.objHasProperty(ev.detail, 'item')
            ) {

                menu.unlisten('MDCMenu:selected', menuItemClickListener);
                menu.destroy();
                return;

            }

            const dataUrl = ev.detail.item.getAttribute('data-url');
            const dataId = ev.detail.item.getAttribute('data-id');

            if (dataUrl === null || dataId === null) {

                this.objCreateShoppingListDialog.open(that.arrUrls.apiCreateShoppingList, (objShoppingList) => {

                    if (objShoppingList === null) {

                        return false;

                    }

                    const objMenu = that.objCartElement.querySelector('[data-ui-role="shopping-list-menu"] .mdc-list');
                    if (objMenu) {

                        that.addShoppingListToMenu(objMenu, objShoppingList.name, objShoppingList.url, objShoppingList.id);

                    }
                    that.addShoppingListToSideList(objShoppingList.name, objShoppingList.url);
                    that.moveToShoppingList(objRow, rowId, objShoppingList.id, objShoppingList.url, objShoppingList.name);

                    return false;

                });

            } else {

                const textItem = ev.detail.item.querySelector('.mdc-list-item__text');
                if (textItem) {

                    that.moveToShoppingList(objRow, rowId, dataId, dataUrl, textItem.textContent);

                }
            }

            menu.unlisten('MDCMenu:selected', menuItemClickListener);
            menu.destroy();

        };

        menu.setIsHoisted(true);
        menu.setAnchorCorner(1);
        menu.setAnchorElement(ev.currentTarget);
        menu.listen('MDCMenu:selected', menuItemClickListener);
        menu.open = true;

        return false;

    }

    initEvents() {

        let that = this;

        const arrDeleteButtons = this.objCartElement.querySelectorAll('[data-ui-role="row-delete-btn"]');

        if (arrDeleteButtons.length) {

            for (const objDeleteButton of arrDeleteButtons) {

                objDeleteButton.addEventListener('click', (ev) => that.onDeleteButtonClick(ev));

            }
        }

        const arrMoveToButtons = this.objCartElement.querySelectorAll('[data-ui-role="row-move-to-btn"]');

        if (arrMoveToButtons.length) {

            for (const objMoveToButton of arrMoveToButtons) {

                objMoveToButton.addEventListener('click', (ev) => that.onMoveToButtonClick(ev));

            }
        }

        const arrQtyInputs = this.objCartElement.querySelectorAll('[data-ui-role="row-qty"]');

        if (arrQtyInputs.length) {

            for (const objQtyInput of arrQtyInputs) {

                objQtyInput.addEventListener('change', (ev) => that.onQtyChange(ev));

            }
        }
    }
}
