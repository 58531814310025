/*jshint esversion: 6 */
import './../styles/index.scss';

import MDCInit from './include/mdc-init';
import GBUIAddToCartVertical from './include/components/gb-ui-add-to-cart-vertical';
import GBUICollapsibles from './include/components/gb-ui-collapsibles';
import GBUIDynamicBreadcrumbs from './include/components/gb-ui-dynamic-breadcrumbs';
import GBUIHexagonalFixedButton from './include/components/gb-ui-hexagonal-fixed-button';
import GBUIPartsList from './include/components/gb-ui-parts-list';
import GBUIShareButtons from './include/components/gb-ui-share-buttons';
import GBUIStickyList from './include/components/gb-ui-sticky-list';
import GBUISnackbar from './include/components/gb-ui-snackbar';
import GBUISuggestEdit from './include/components/gb-ui-suggest-edit';
import GBUIToggleVisibility from './include/components/gb-ui-toggle-visibility';
import GBUIPopStateCatcher from './include/components/gb-ui-popstate-catcher';
import GBUIUnitsSwitcher from './include/components/gb-ui-units-switcher';
import GBUIUserEditProfile from './include/modules/gb-ui-user-edit_profile';
import GBUIGarageAdd from './include/modules/gb-ui-garage-add';
import GBUIImageList from './include/components/gb-ui-image-list';
import GBUIImageSlider from './include/components/gb-ui-image-slider';
import GBUIImageSliderGarage from './include/components/gb-ui-image-slider-garage';
import GBUIAddToGarage from './include/components/gb-ui-add-to-garage';
import GBUICart from './include/modules/gb-ui-cart';
import GBUIInfoDataGallery from './include/components/gb-ui-info-data-gallery';

(function () {

    global.gbui = {};

    global.gbui.objMDCInit = new MDCInit();

    global.gbui.GBUIAddToCartVertical = GBUIAddToCartVertical;
    global.gbui.GBUICollapsibles = new GBUICollapsibles();
    global.gbui.GBUIDynamicBreadcrumbs = GBUIDynamicBreadcrumbs;
    global.gbui.GBUIPartsList = GBUIPartsList;
    global.gbui.GBUIShareButtons = new GBUIShareButtons();
    global.gbui.GBUIStickyList = GBUIStickyList;
    global.gbui.GBUISuggestEdit = GBUISuggestEdit;
    global.gbui.GBUIHexagonalFixedButton = GBUIHexagonalFixedButton;
    global.gbui.GBUIUnitsSwitcher = GBUIUnitsSwitcher;
    global.gbui.GBUIUserEditProfile = GBUIUserEditProfile;
    global.gbui.GBUIPopStateCatcher = GBUIPopStateCatcher;
    global.gbui.GBUIToggleVisibility = GBUIToggleVisibility;
    global.gbui.GBUIGarageAdd = GBUIGarageAdd;
    global.gbui.GBUIImageList = GBUIImageList;
    global.gbui.GBUIImageSlider = GBUIImageSlider;
    global.gbui.GBUIImageSliderGarage = GBUIImageSliderGarage;
    global.gbui.GBUIAddToGarage = GBUIAddToGarage;
    global.gbui.GBUICart = GBUICart;
    global.gbui.GBUIInfoDataGallery = GBUIInfoDataGallery;
    global.gbui.GBUISnackbar = GBUISnackbar;

})();
