/*jshint esversion: 6 */

import GBUIFilesBase from './gb-ui-files-base';
import GBUILinearProgressGlobal from './gb-ui-linear-progress-global';
import GBUIPopStateCatcher from './gb-ui-popstate-catcher';
import GBUIRemoveConfirmation from './gb-ui-remove-confirmation';

export default class GBUIImageSlider extends GBUIFilesBase {

	constructor(arrEntities, arrUrls) {

		super(arrEntities, arrUrls, 'image/*');

		this.sNoMoreImages = 'No more images found';

		this.gallery = null;
		this.popStateCatcher = new GBUIPopStateCatcher();

		this.mediaPerPage = 20;
		this.objLoadMoreController = null;
		this.objProgressBar = new GBUILinearProgressGlobal();
		this.lastId = Number.MAX_SAFE_INTEGER;
		this.initEvents();

	}

	noMoreImages(objButton) {

		const objButtonLabel = objButton.querySelector('.mdc-button__label');
		if (objButtonLabel) {

			objButtonLabel.innerText = this.sNoMoreImages;
			
		}
	}

	convertDOMtoPSWP(objEntity) {

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return [];

		}

		let res = [];

		const arrElements = filesContainer.querySelectorAll('li a[data-sizes]');
		for(const element of arrElements) {

			const sizes = element.getAttribute('data-sizes');
			if (!sizes) {

				continue;

			}

			let img = {
				'url': element.href
			};

			const objImg = element.querySelector('.gb-image-slider__image');
			if (objImg) {
			
				if (objImg.src) {

					img.msrc = objImg.src;

				}
			}

			const arrSizes = sizes.split(',');
			
			for(const size of arrSizes) {

				const prefixAttr = 'data-size-'+ size +'-';

				let item = {};
				item.src = element.getAttribute(prefixAttr + 'url');
				item.w = element.getAttribute(prefixAttr + 'width');
				item.h = element.getAttribute(prefixAttr + 'height');

				if (!item.src || !item.w || !item.h) {

					continue;

				}

				img[size] = item;

			}

			res.push(img);

		}

		return res;

	}

	createGallery(objEntity, objListElement, objElement) {
		
		let that = this;

		const PhotoSwipe = require('photoswipe');
		const PhotoSwipeUI = require('./../libraries/pswp_ui.js');
		const objPswpElement = document.getElementById('pswp');

		if (!objPswpElement) {

			return;

		}

		const arrElements = objListElement.querySelectorAll('li a[data-sizes]');

		const options = {
			'index': Array.from(arrElements).indexOf(objElement),
			'showHideOpacity':false,
			'history': false,
			// 'tapToToggleControls': false,
			'getThumbBoundsFn': function(index) {

				// find thumbnail element
				const thumbnail = arrElements[index];

				// get window scroll Y
				const pageYScroll = window.pageYOffset || document.documentElement.scrollTop; 
				// optionally get horizontal scroll

				// get position of element relative to viewport
				const rect = thumbnail.getBoundingClientRect(); 

				// w = width
				return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};

				// Good guide on how to get element coordinates:
				// http://javascript.info/tutorial/coordinates
			}
		};

		const items = this.convertDOMtoPSWP(objEntity);

		global.pushStateEmitters = global.pushStateEmitters || {};
		global.pushStateEmitters.gallery = this.gallery = new PhotoSwipe(objPswpElement, PhotoSwipeUI, items, options);

		let realViewportWidth,
			preferredSize = 'thumb',
			firstResize = true,
			imageSrcWillChange;

		// beforeResize event fires each time size of gallery viewport updates
		this.gallery.listen('beforeResize', function() {
			// that.gallery.viewportSize.x - width of PhotoSwipe viewport
			// that.gallery.viewportSize.y - height of PhotoSwipe viewport
			// window.devicePixelRatio - ratio between physical pixels and device independent pixels (Number)
			//                          1 (regular display), 2 (@2x, retina) ...

			// calculate real pixels when size changes
			realViewportWidth = that.gallery.viewportSize.x * window.devicePixelRatio;

			// Code below is needed if you want image to switch dynamically on window.resize

			// Find out if current images need to be changed
			if(preferredSize !== 'thumb' && realViewportWidth <= 640) {
			
				preferredSize = 'thumb';
				imageSrcWillChange = true;
			
			} else if(preferredSize !== 'medium' && realViewportWidth > 640 && realViewportWidth <= 1280) {
				
				preferredSize = 'medium';
				imageSrcWillChange = true;
			
			} else if(preferredSize !== 'large' && realViewportWidth > 1280 && realViewportWidth <= 4096) {
			
				preferredSize = 'large';
				imageSrcWillChange = true;

			} else if(preferredSize !== 'source' && realViewportWidth > 4096) {
			
				preferredSize = 'source';
				imageSrcWillChange = true;
			
			}

			// Invalidate items only when source is changed and when it's not the first update
			if(imageSrcWillChange && !firstResize) {
				// invalidateCurrItems sets a flag on slides that are in DOM,
				// which will force update of content (image) on window.resize.
				that.gallery.invalidateCurrItems();
			}

			if(firstResize) {
				firstResize = false;
			}

			imageSrcWillChange = false;

		});

		this.gallery.listen('initialZoomIn', function() {

			const objButton = objPswpElement.querySelector('.pswp__button--zoom');
			if (objButton) {

				objButton.innerText = 'zoom_in';

			}
		});

		this.gallery.listen('initialZoomOut', function() {
			
			const objButton = objPswpElement.querySelector('.pswp__button--zoom');
			if (objButton) {

				objButton.innerText = 'zoom_out';

			}
		});

		this.gallery.listen('destroy', function() {

			if (global.pushStateEmitters !== undefined && global.pushStateEmitters.gallery !== undefined) {

				delete global.pushStateEmitters.gallery;

			}
		});

		// gettingData event fires each time PhotoSwipe retrieves image source & size
		this.gallery.listen('gettingData', function(index, item) {

			if (that.objHasProperty(item, preferredSize)) {

				item.src = item[preferredSize].src;
				item.w = item[preferredSize].w;
				item.h = item[preferredSize].h;
				return;

			}

			for(const s of ['thumb', 'medium', 'large', 'source']) {

				if (that.objHasProperty(item, s)) {

					item.src = item[s].src;
					item.w = item[s].w;
					item.h = item[s].h;
					return;

				}				
			}

			// It doesn't really matter what will you do here, 
			// as long as item.src, item.w and item.h have valid values.
			// 
			// Just avoid http requests in this listener, as it fires quite often

		});

		this.gallery.init();
	}

	onImageClick(ev) {

		ev = ev || window.event;

		const pushStateSupport = ('pushState' in history);
		if (!pushStateSupport) {

			return true;

		}

		const objUl = ev.currentTarget.parentElement.parentElement;
		if (!objUl) {

			return true;

		}

		const hash = objUl.getAttribute('data-entity-key');
		if (!hash) {

			return true;

		}

		if (!this.mapEntities.has(hash)) {

			return true;

		}

		const objEntity = this.mapEntities.get(hash);

		this.createGallery(objEntity, objUl, ev.currentTarget)

		ev.preventDefault ? ev.preventDefault() : ev.returnValue = false;

		return false;

	}

	loadMore(objEntity, objButton) {

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return;

		}

		let that = this;

		objButton.disabled = true;
		
		if (this.objProgressBar) {

			this.objProgressBar.open();

		}

		const data = {
			// 'entity_type': objEntity.type,
			// 'entity_id': objEntity.id,
			'last_id': this.lastId,
		};
		const url = this.arrUrls['more'] +'?'+ this.encodeQueryData(data);

		if (that.objLoadMoreController) {

			that.objLoadMoreController.abort();
			// console.log('abort');

		}
		that.objLoadMoreController = new AbortController();

		// console.log('start');
		fetch(url, {signal: that.objLoadMoreController.signal})
		.then(response => response ? response.json() : null)
		.then(response => {

			if (that.objProgressBar) {

				that.objProgressBar.close();

			}

			if (that.objHasProperty(response, 'errors') && response.errors !== null) {

				objButton.disabled = false;
				that.showSnackbar(that.sErrorMessage);
				return;

			}

			if (!that.objHasProperty(response, 'html') || !response.html) {

				that.noMoreImages(objButton);
				return;

			}

			let template = document.createElement('template');
			template.innerHTML = response.html;

			if (template.content.children.length < that.mediaPerPage) {

				that.noMoreImages(objButton);

			} else {

				objButton.disabled = false;

			}

			while (template.content.children.length) {

				const child = template.content.firstChild;
				that.initElementEvents(child);
				filesContainer.appendChild(child);

			}
		});
	}

	onLoadMore(ev) {

		const sEntityKey = ev.currentTarget.getAttribute('data-entity-key');

		if (!this.mapEntities.has(sEntityKey)) {

			return;

		}

		const objEntity = this.mapEntities.get(sEntityKey);

		if (
			!objEntity ||
			typeof objEntity !== 'object' ||
			objEntity.id === undefined ||
			objEntity.type === undefined
		) {

			return;

		}

		this.loadMore(objEntity, ev.currentTarget);

	}

	onDeleteElementClick(ev) {

		const listElement = ev.currentTarget.parentElement;

		const sEntityKey = listElement.parentElement.parentElement.getAttribute('data-entity-key');

		if (!this.mapEntities.has(sEntityKey)) {

			return;

		}

		const objEntity = this.mapEntities.get(sEntityKey);
		if (!this.isObj(objEntity)) {

			return;

		}

		const objRemoveDialog = new GBUIRemoveConfirmation('image', () => {

			listElement.classList.add('gb-image-slider__item--removed');

			setTimeout(() => {

				const workerUid = listElement.getAttribute('data-worker-id');
				const mediaEntityId = listElement.getAttribute('data-id');

				this.deleteFile(objEntity, mediaEntityId ? mediaEntityId : null, workerUid ? workerUid : null);

			}, 200);
		});

		objRemoveDialog.open();

	}

	removeFileElement(objEntity, mediaEntityId = null, sWorkerUid = null) {

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return false;

		}

		if (sWorkerUid) {

			const objElement = filesContainer.querySelector('li[data-worker-id="'+ sWorkerUid +'"]');

			if (objElement) {

				objElement.parentElement.removeChild(objElement);

			}
		}

		if (mediaEntityId) {

			const objElement = filesContainer.querySelector('li[data-id="'+ mediaEntityId +'"]');

			if (objElement) {

				objElement.parentElement.removeChild(objElement);

			}
		}

		// Load more if we have less than a full
		const arrListElements = filesContainer.querySelectorAll('li.gb-image-slider__item');
		if (arrListElements.length > 0 && arrListElements.length < this.mediaPerPage) {

			const objButton = objEntity.container.querySelector('[data-ui-role="button-more"]');
			if (objButton && !objButton.disabled) {

				this.loadMore(objEntity, objButton);

			}
		}
	}

	initEvents() {

		let that = this;

		for(let [entityHash, objEntity] of this.mapEntities) {

			const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
			if (!filesContainer) {

				continue;

			}

			filesContainer.setAttribute('data-entity-key', entityHash);

			const arrListElements = filesContainer.querySelectorAll('li.gb-image-slider__item');
			if (!arrListElements) {

				continue;

			}

			for (const objListElement of arrListElements) {

				this.initElementEvents(objListElement);

			}


			const loadMoreButton = objEntity.container.querySelector('[data-ui-role="button-more"]');
			if (loadMoreButton) {

				loadMoreButton.setAttribute('data-entity-key', entityHash);

				loadMoreButton.addEventListener('click', (ev) => {

					that.onLoadMore(ev);

				});
			}
		}
	}

	initElementEvents(objElem) {

		let that = this;

		const objButton = objElem.querySelector('[data-ui-role="delete-button"]');
		if (objButton) {

			objButton.addEventListener('click', ev => that.onDeleteElementClick(ev));

		}

		const objA = objElem.querySelector('a');
		if (objA) {

			objA.addEventListener('click', ev => that.onImageClick(ev));

		}

		const dataId = parseInt(objElem.getAttribute('data-id'));
		if (dataId > 0 && dataId < this.lastId)  {

			this.lastId = dataId;
			// console.log(this.lastId);

		}

		global.gbui.objMDCInit.initElements(objElem);

	}

	// 
	// Creates file element in the list
	//
	createFileElement(objEntity, sWorkerUid, file) {

		if (!this.isImage(file)) {

			return false;

		}

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return false;

		}

		// <li class="gb-image-slider__item">
		// 	<img class="gb-image-slider__image" src="https://material-components.github.io/material-components-web-catalog/static/media/photos/3x2/16.jpg" alt="Text label">
		// </li>

		const objLi = document.createElement('li');
		objLi.setAttribute('class', 'gb-image-slider__item');
		objLi.setAttribute('data-status', 'uploading');
		objLi.setAttribute('data-worker-id', sWorkerUid);

		const objA = document.createElement('a');

		const objImg = document.createElement('img');
		objImg.setAttribute('class', 'gb-image-slider__image');
		objImg.setAttribute('alt', 'Uploading...');
		objImg.setAttribute('src', 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0ibG9nby1ncmFkaWVudCIgeDE9Ii0xNTAlIiB5MT0iNTAlIiB4Mj0iMCUiIHkyPSI1MCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlZmVmZWYiPjwvc3RvcD48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2ZjZmNmYyI+PC9zdG9wPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VmZWZlZiI+PC9zdG9wPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9IngxIiBkdXI9IjJzIiBmcm9tPSItMTUwJSIgdG89IjIwMCUiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9IngyIiBkdXI9IjJzIiBmcm9tPSIwJSIgdG89IjM1MCUiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGZpbGw9InVybCgnI2xvZ28tZ3JhZGllbnQnKSIgZD0iTTkwLDgxLjExMWwwLC02Mi4yMjJjMCwtNC44ODkgLTQsLTguODg5IC04Ljg4OSwtOC44ODlsLTYyLjIyMiwwYy00Ljg4OSwwIC04Ljg4OSw0IC04Ljg4OSw4Ljg4OWwwLDYyLjIyMmMwLDQuODg5IDQsOC44ODkgOC44ODksOC44ODlsNjIuMjIyLDBjNC44ODksMCA4Ljg4OSwtNCA4Ljg4OSwtOC44ODlabS0xNi45MzMsLTUxLjA3OGw2LjkzMywxOS45NjdsMCwyNi42NjdjMCwxLjgzMyAtMS41LDMuMzMzIC0zLjMzMywzLjMzM2wtMy4zMzQsMGMtMS44MzMsMCAtMy4zMzMsLTEuNSAtMy4zMzMsLTMuMzMzbDAsLTMuMzM0bC00MCwwbDAsMy4zMzRjMCwxLjgzMyAtMS41LDMuMzMzIC0zLjMzMywzLjMzM2wtMy4zMzQsMGMtMS44MzMsMCAtMy4zMzMsLTEuNSAtMy4zMzMsLTMuMzMzbDAsLTI2LjY2N2w2LjkzMywtMTkuOTY3YzAuNywtMS45NjYgMi41MzQsLTMuMzY2IDQuNzM0LC0zLjM2NmwzNi42NjYsMGMyLjIsMCA0LjA2NywxLjQgNC43MzQsMy4zNjZabS00MS40LDMzLjNjMi43NjYsMCA1LC0yLjIzMyA1LC01YzAsLTIuNzY2IC0yLjIzNCwtNSAtNSwtNWMtMi43NjcsMCAtNSwyLjIzNCAtNSw1YzAsMi43NjcgMi4yMzMsNSA1LDVabTM2LjY2NiwwYzIuNzY3LDAgNSwtMi4yMzMgNSwtNWMwLC0yLjc2NiAtMi4yMzMsLTUgLTUsLTVjLTIuNzY2LDAgLTUsMi4yMzQgLTUsNWMwLDIuNzY3IDIuMjM0LDUgNSw1Wm0tNDEuNjY2LC0xNi42NjZsNDYuNjY2LDBsLTUsLTE1bC0zNi42NjYsMGwtNSwxNVoiLz48L3N2Zz4=');
		objImg.height = objImg.width = 200;

		objA.appendChild(objImg);

		const objProgress = this.createCircularProgress('indeterminate', 'medium');
		objProgress.classList.add('gb-image-slider__image__progress');

		const objDeleteButton = document.createElement('button');
		objDeleteButton.setAttribute('data-ui-role', 'delete-button');
		objDeleteButton.setAttribute('class', 'mdc-icon-button i gb-image-slider__image__button-delete');
		objDeleteButton.setAttribute('data-mdc-auto-init', 'MDCRipple');
		objDeleteButton.setAttribute('data-mdc-ripple-is-unbounded', 'data-mdc-ripple-is-unbounded');
		objDeleteButton.innerText = 'delete';

		const objReader = new FileReader();
		objReader.onload = function(e) {

			objImg.src = e.target.result;
			objImg.removeAttribute('width');
			objImg.removeAttribute('height');

		}
		objReader.readAsDataURL(file);

		objLi.appendChild(objA);
		objLi.appendChild(objDeleteButton);
		objLi.appendChild(objProgress);

		filesContainer.prepend(objLi);

		this.initElementEvents(objLi);

		return true;

	}

	listChanges(objEntity) {

		const isEmpty = objEntity.container.querySelectorAll('li.gb-image-slider__item').length === 0;

		if (this.objHasProperty(objEntity, 'arrHideOnEmptySelectors')) {

			for(const strSelector of objEntity.arrHideOnEmptySelectors) {

				const arrElements = objEntity.container.querySelectorAll(strSelector);

				if (arrElements) {

					for(const objElem of arrElements) {

						objElem.classList.toggle('hidden', isEmpty);

					}
				}
			}
		}

		if (this.objHasProperty(objEntity, 'arrShowOnEmptySelectors')) {

			for(const strSelector of objEntity.arrShowOnEmptySelectors) {

				const arrElements = objEntity.container.querySelectorAll(strSelector);

				if (arrElements) {

					for(const objElem of arrElements) {

						objElem.classList.toggle('hidden', !isEmpty);

					}
				}
			}
		}
	}

	//
	// Picks most suitable image for a desirable size
	//
	getImageFile(objMedia, size = 'thumb') {

		if (this.objHasProperty(objMedia, 'sizes')) {

			const arrSizes = ['thumb', 'medium', 'large', 'source'];
			let start = arrSizes.indexOf(size);
			if (start < 0) start = 0;

			for (let i = start; i <= arrSizes.length; i++) { 
				
				for(const size of objMedia.sizes) {

					if (
						this.objHasProperty(size, 'media_size') && 
						size.media_size === arrSizes[i] &&
						this.objHasProperty(size, 'file') && 
						this.objHasProperty(size.file, 'url')
					) {

						return {
							'file': size.file,
							'width': size.width,
							'height': size.height,
						};

					}
				}
			}
		}

		return this.objHasProperty(objMedia, 'file') && this.objHasProperty(objMedia.file, 'url') ? {'file': objMedia.file} : null;

	}


	updateFileElementError(objEntity, sWorkerUid) {

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return false;

		}

		const elem = filesContainer.querySelector('[data-worker-id="'+ sWorkerUid +'"]');
		if (!elem) {

			return false;

		}

		// Hide progressbar
		const elemProgressbar = elem.querySelector('[role="progressbar"]');
		if (elemProgressbar) {

			elemProgressbar.classList.add('mdc-circular-progress--closed');

		}
	}

	// 
	// Updates file element
	//
	updateFileElement(objEntity, sWorkerUid, iProgress = 0, objMediaEntity = null) {

		// Upload is complete
		if (
			iProgress !== 100 ||
			!this.isObj(objMediaEntity) ||
			!this.objHasProperty(objMediaEntity, 'id') ||
			!this.objHasProperty(objMediaEntity, 'media')
		) {

			return false;

		}

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return false;

		}

		const elem = filesContainer.querySelector('[data-worker-id="'+ sWorkerUid +'"]');
		if (!elem) {

			return false;

		}

		// Remove worker and data status attributes
		elem.removeAttribute('data-worker-id');
		elem.removeAttribute('data-status');

		// Hide progressbar
		const elemProgressbar = elem.querySelector('[role="progressbar"]');
		if (elemProgressbar) {

			elemProgressbar.classList.add('mdc-circular-progress--closed');

		}

		// Add media id
		elem.setAttribute('data-id', objMediaEntity.id);

		// Update last id if necessary
		if (objMediaEntity.id < this.lastId) {

			this.lastId = objMediaEntity.id;

		}

		const objA = elem.querySelector('a');
		if (objA) {

			if (this.objHasProperty(objMediaEntity.media, 'name')) {

				objA.setAttribute('title', objMediaEntity.media.name);

			}

			const objFile = this.getImageFile(objMediaEntity.media, 'large');
			if (this.isObj(objFile) && this.objHasProperty(objFile, 'file')) {

				if (this.objHasProperty(objEntity, 'urlTemplate')) {

					objA.href = objEntity.urlTemplate.replace('%mediaEntityId%', objMediaEntity.id);

				} else {

					objA.href = objFile.file.url;

				}
			}

			if (this.objHasProperty(objMediaEntity.media, 'sizes')) {

				const arrSizes = [];

				for(const size of objMediaEntity.media.sizes) {

					if (
						this.objHasProperty(size, 'file') &&
						this.objHasProperty(size, 'media_size') &&
						this.objHasProperty(size, 'width') &&
						this.objHasProperty(size, 'height') &&
						this.objHasProperty(size.file, 'url')
					) {

						objA.setAttribute('data-size-'+ size.media_size +'-url', size.file.url);
						objA.setAttribute('data-size-'+ size.media_size +'-width', size.width);
						objA.setAttribute('data-size-'+ size.media_size +'-height', size.height);
						arrSizes.push(size.media_size);

					}
				}

				if (arrSizes.length) {

					objA.setAttribute('data-sizes', arrSizes.join(','));

				}
			}
		}

		// Update img
		const elemImage = elem.querySelector('.gb-image-slider__image');
		if (elemImage) {

			const objFile = this.getImageFile(objMediaEntity.media);
			if (this.isObj(objFile) && this.objHasProperty(objFile, 'file')) {

				elemImage.src = objFile.file.url;

				if (
					this.objHasProperty(objFile, 'width') &&
					this.objHasProperty(objFile, 'height')
				) {

					elemImage.width = objFile.width;
					elemImage.height = objFile.height;

				}


				if (this.objHasProperty(objMediaEntity.media, 'name')) {

					elemImage.setAttribute('alt', objMediaEntity.media.name);

				}
			}
		}

		return true;

	}
}
