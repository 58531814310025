/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUISnackbar from './gb-ui-snackbar';

export default class GBUIAddToGarage extends GBUIBase {

	constructor(containerElement, markId, modelId, year, generationId, arrUrls) {

		super();

		this.containerElement = containerElement;

		if (this.containerElement) {

			this.buttonElement = this.containerElement.querySelector('[data-ui-role="add-car-button"]');

		} else {

			this.buttonElement = null;

		}

		this.markId = markId;
		this.modelId = modelId;
		this.year = year;
		this.generationId = generationId;
		this.arrUrls = arrUrls;

		this.objSnackbar = GBUISnackbar;

		this.initEvents();

	}

	onAddCar(ev) {

		let that = this;

		const data = {
			'mark_id': this.markId,
			'model_id': this.modelId,
			'generation_id': this.generationId,
			'year': this.year,
		};

		this.buttonElement.setAttribute('disabled', 'disabled');

		const progressElement = this.createCircularProgress('indeterminate', 'small');
		if (progressElement) {

			const iconContainer = this.buttonElement.querySelector('.mdc-button__icon');
			if (iconContainer) {

				iconContainer.textContent = '';
				iconContainer.appendChild(progressElement);

			}
		}

		this.makeRequest(this.arrUrls['car_add'], 'POST', data, function(arrData, objErrors) {

			if (objErrors === null) {

				that.containerElement.innerHTML = 'Car was added. You can find it in <a href="/garage/" title="Your garage">your garage</a>.';

			} else {

				// Have some errors
				if (that.objSnackbar) {

					that.objSnackbar.open("An error occured. Please try again later.");
				}

			}
		});
	}

	initEvents() {

		let that = this;

		if (this.buttonElement) {

			this.buttonElement.addEventListener('click', (ev) => {

				that.onAddCar(ev);

			});
		}
	}
}

