/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUIHexagonalFixedButton extends GBUIBase {

	constructor(element) {

		super();
		this.element = element;
		this.initBtnEvents();

	}

	fireEvent(event) {

		const arrButtons = document.querySelectorAll('[data-ui-role="hexagonal-fixed-button"]');
		for (const btn of arrButtons) {

			const fabEvent = new CustomEvent(event, {'detail': this.getCurrentLevel()});
			btn.dispatchEvent(fabEvent);

		}
	}

	getCurrentLevel() {

		const classes = this.element.getAttribute('class');
		const regexp = /gb-fab--pos-l([\d]+)/i;
		const match = regexp.exec(classes);
		if (match && match[1] !== undefined) {
			
			return parseInt(match[1]);

		}

		return 0;
	}

	initBtnEvents() {

		let that = this;

		this.element.addEventListener('hexagonalButtonActive', objEvent => {

			const level = that.getCurrentLevel();
			if (level - objEvent.detail === 1)  {

				that.element.classList.add('gb-suggest-edit__button--faded');

			}
			objEvent.stopPropagation();

		});

		this.element.addEventListener('hexagonalButtonInactive', objEvent => {

			that.element.classList.remove('gb-suggest-edit__button--faded');
			objEvent.stopPropagation();

		});

		// Container events
		this.element.addEventListener('mouseenter', $event => {

			that.fireEvent('hexagonalButtonActive');

		});

		// Container events
		this.element.addEventListener('mouseleave', $event => {

			that.fireEvent('hexagonalButtonInactive');
			
		});
	}
}