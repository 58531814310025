/*jshint esversion: 6 */

import GBUIHexagonalFixedButton from './gb-ui-hexagonal-fixed-button';

export default class GBUIUnitsSwitcher extends GBUIHexagonalFixedButton {

	constructor(element, arrSystemOfMeasurement, currentSystemOfMeasurement) {

		super(element);

		this.element = element;
		this.arrSystemOfMeasurement = arrSystemOfMeasurement;
		this.currentSystemOfMeasurement = currentSystemOfMeasurement;
		this.collapsed = true;

		this.initEvents();

	}

	switchUnitSystem(systemOfMeasurement = null) {

		// Switch systemOfMeasurement
		if (!systemOfMeasurement || this.arrSystemOfMeasurement[systemOfMeasurement] === undefined) {

			return;

		}

		this.currentSystemOfMeasurement = systemOfMeasurement;

		this.setCookie('system_of_measurement', systemOfMeasurement, 3650);

		// Switch elements
		let arrInfoSets = document.querySelectorAll('.gb-info-set');

		for(let i = 0; i < arrInfoSets.length; i++) {

			let arrInfos = arrInfoSets[i].querySelectorAll('.gb-info');
			
			for(let j = 0; j < arrInfos.length; j++) {

				let infoSystemOfMeasurement = arrInfos[j].getAttribute('data-s');
				
				if (this.currentSystemOfMeasurement===infoSystemOfMeasurement) {

					arrInfos[j].classList.remove('hidden');

				} else {

					arrInfos[j].classList.add('hidden');

				}
			}
		}

		// // Switch Switchers captions
		// let arrUnitSwitchers = document.querySelectorAll('.gb-units-switcher');

		// for(let i = 0; i < arrUnitSwitchers.length; i++) {

		// 	let chipText = arrUnitSwitchers[i].getElementsByClassName('mdc-chip__text');
		// 	if (chipText && chipText.length===1) {

		// 		chipText[0].innerHTML = this.arrSystemOfMeasurement[system];

		// 	}
		// }
	}

	show() {

		let that = this;
		let arrUnitSwitcherButtons = that.element.querySelectorAll('[data-ui-role="units-switcher-button"]');

		for(let i = 0; i < arrUnitSwitcherButtons.length; i++) {

			let unitSwitcherButtonsDataId = arrUnitSwitcherButtons[i].getAttribute('data-id');
			if (unitSwitcherButtonsDataId !== that.currentSystemOfMeasurement) {

				arrUnitSwitcherButtons[i].parentElement.classList.add('gb-floatable-units-switcher__button--visible');

			}
		}

		let objUnitSwitcherLabel = that.element.querySelector('[data-ui-role="units-switcher-label"]');
		if (objUnitSwitcherLabel) {

			objUnitSwitcherLabel.classList.add('gb-floatable-units-switcher__button--visible');

		}

		that.collapsed = false;

	}

	hide(systemOfMeasurement) {

		let that = this;

		let arrUnitSwitcherButtons = that.element.querySelectorAll('[data-ui-role="units-switcher-button"]');
		let index = 2;

		for(let i = 0; i < arrUnitSwitcherButtons.length; i++) {

			let indexCurrent = 0;

			for (let j = 1; j <= arrUnitSwitcherButtons.length; j++) {

				if (arrUnitSwitcherButtons[i].parentElement.classList.contains('gb-floatable-units-switcher__button--'+ j)) {

					indexCurrent = j;
					break;

				}
			}

			if (indexCurrent === 0) {

				// smth wrong happened
				continue;

			}

			let unitSwitcherButtonsDataId = arrUnitSwitcherButtons[i].getAttribute('data-id');

			if (unitSwitcherButtonsDataId === that.currentSystemOfMeasurement) {

				arrUnitSwitcherButtons[i].parentElement.classList.replace('gb-floatable-units-switcher__button--'+ indexCurrent, 'gb-floatable-units-switcher__button--1');

			} else {

				arrUnitSwitcherButtons[i].parentElement.classList.replace('gb-floatable-units-switcher__button--'+ indexCurrent, 'gb-floatable-units-switcher__button--'+ index);

				index++;

			}

			arrUnitSwitcherButtons[i].parentElement.classList.remove('gb-floatable-units-switcher__button--visible');

		}

		let objUnitSwitcherLabel = that.element.querySelector('[data-ui-role="units-switcher-label"]');
		if (objUnitSwitcherLabel) {

			objUnitSwitcherLabel.classList.remove('gb-floatable-units-switcher__button--visible');
			
		}

		that.collapsed = true;

	}

	initEvents() {

		let that = this;

		// Buttons
		const unitSwitcherButtonClickHandler = function(event) {

			const targetElement = event.target || event.srcElement;
			if (targetElement) {

				const targetElementDataId = targetElement.getAttribute('data-id');

				if (targetElementDataId && targetElementDataId === that.currentSystemOfMeasurement && that.collapsed) {

					// first button clicked and other buttons are hidden
					that.show();

				} else {

					// pick an unit system
					that.switchUnitSystem(targetElementDataId);
					that.hide(targetElementDataId);

				}
			}
		};

		const arrUnitSwitcherButtons = that.element.querySelectorAll('[data-ui-role="units-switcher-button"]');

		for(let i = 0; i < arrUnitSwitcherButtons.length; i++) {

			arrUnitSwitcherButtons[i].addEventListener('click', unitSwitcherButtonClickHandler, false);

		}
	}
}