/*jshint esversion: 6 */

import GBUIFilesBase from './gb-ui-files-base';
import GBUILinearProgressGlobal from './gb-ui-linear-progress-global';
import GBUIPopStateCatcher from './gb-ui-popstate-catcher';
import GBUIImageSlider from './gb-ui-image-slider';

export default class GBUIImageSliderGarage extends GBUIImageSlider {

	constructor(arrEntities, arrUrls) {

		super(arrEntities, arrUrls);

	}

	onSetCoverElementClick(ev) {

		const listElement = ev.currentTarget.parentElement;
		const mediaEntityId = listElement.getAttribute('data-id');

		const sEntityKey = listElement.parentElement.parentElement.getAttribute('data-entity-key');

		if (!this.mapEntities.has(sEntityKey)) {

			return;

		}

		const objEntity = this.mapEntities.get(sEntityKey);
		if (!this.isObj(objEntity)) {

			return;

		}

		const aElement = listElement.querySelector('a');
		if (aElement) {

			const imgUrl = aElement.getAttribute('data-size-source-url');

			if (imgUrl) {

				objEntity.coverElement.style.backgroundImage = 'url('+ imgUrl +')';

			}
		}

		// Remove media entity record
		if (mediaEntityId) {

			const data = {
				'media_entity_id': mediaEntityId
			};
			const url = this.arrUrls['set-cover'];

			this.makeRequest(url, 'POST', data);

		}
	}

	initElementEvents(objElem) {

		let that = this;

		const objButton = objElem.querySelector('[data-ui-role="set-cover-button"]');
		if (objButton) {

			objButton.addEventListener('click', ev => that.onSetCoverElementClick(ev));

		}

		super.initElementEvents(objElem);

	}

	// 
	// Updates file element
	//
	updateFileElement(objEntity, sWorkerUid, iProgress = 0, objMediaEntity = null) {

		let that = this;

		// Upload is complete
		if (
			iProgress !== 100 ||
			!this.isObj(objMediaEntity) ||
			!this.objHasProperty(objMediaEntity, 'id') ||
			!this.objHasProperty(objMediaEntity, 'media')
		) {

			return false;

		}

		const filesContainer = objEntity.container.querySelector('ul.gb-image-slider');
		if (!filesContainer) {

			return false;

		}

		const objLi = filesContainer.querySelector('[data-worker-id="'+ sWorkerUid +'"]');
		if (!objLi) {

			return false;

		}

		const objSetCoverButton = document.createElement('button');
		objSetCoverButton.setAttribute('data-ui-role', 'set-cover-button');
		objSetCoverButton.setAttribute('class', 'mdc-icon-button i gb-image-slider__image__button-set-cover');
		objSetCoverButton.setAttribute('data-mdc-auto-init', 'MDCRipple');
		objSetCoverButton.setAttribute('data-mdc-ripple-is-unbounded', 'data-mdc-ripple-is-unbounded');
		objSetCoverButton.innerText = 'fullscreen';
		objSetCoverButton.addEventListener('click', ev => that.onSetCoverElementClick(ev));

		objLi.appendChild(objSetCoverButton);

		super.updateFileElement(objEntity, sWorkerUid, iProgress, objMediaEntity);

	}
}
