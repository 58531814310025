/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUIDialog from './../components/gb-ui-dialog';
// import GBUISnackbar from './gb-ui-snackbar';

export default class GBUIRemoveConfirmation extends GBUIBase {

	constructor(entityName, cb) {

		super();

		// let that = this;

		// this.objSnackbar = GBUISnackbar;
		this.entityName = entityName;
		this.objDialogElement = document.getElementById('remove-confirmation-dialog');
		this.cb = cb;

		this.objDialog = new GBUIDialog(this.objDialogElement, [], this.cb);
		this.dialogStringElement = document.getElementById('remove-confirmation-dialog-content');
		
	}

	open() {

		var that = this;

		if (this.dialogStringElement) {

			this.dialogStringElement.innerHTML = '<h6>Do you really want to remove this '+ this.entityName +'?</h6>';

		}

		this.objDialog.open();

	}
}