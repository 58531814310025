/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUICollapsibles extends GBUIBase {

	constructor() {

		super();
		this.initEvents();

	}

	recalculateScroll() {

		// insert element
		container.insertBefore(elem, container.firstChild);
		// measure inserter element height and adjust scroll pos
		// var elemHeight = elem.offsetHeight;

	}

	show(controlElement, collapsibleElement, recalculateScroll = false) {

		// let that = this;
		let prevOffset = window.pageYOffset;

		if (collapsibleElement.classList.contains('gb-collapsible--animated')) {

			this.expandSection(collapsibleElement);

		}

		controlElement.setAttribute('aria-expanded', 'true');
		collapsibleElement.setAttribute('aria-expanded', 'true');

		if (recalculateScroll) {

			window.scrollTo(0, prevOffset + collapsibleElement.offsetHeight);

		}
	}

	hide(controlElement, collapsibleElement, recalculateScroll = false) {

		// let that = this;
		let prevOffset = window.pageYOffset;
		let elemHeight = collapsibleElement.offsetHeight;

		if (collapsibleElement.classList.contains('gb-collapsible--animated')) {
		
			this.collapseSection(collapsibleElement);

		}

		controlElement.setAttribute('aria-expanded', 'false');
		collapsibleElement.setAttribute('aria-expanded', 'false');

		if (recalculateScroll) {

			window.scrollTo(0, prevOffset - elemHeight);

		}
	}

	initEvents() {

		let that = this;

		// Buttons
		let collapsibleClick = function(event) {

			let targetElement = event.target || event.srcElement;
			if (targetElement) {

				let arrRecalculateScrollForIds = [];
				let recalculateScrollFor = targetElement.getAttribute('data-recalculate-scroll-for');
				if (recalculateScrollFor) {

					arrRecalculateScrollForIds = recalculateScrollFor.split(" ");

				}

				let collapsibleElementIds = targetElement.getAttribute('aria-controls');

				if (collapsibleElementIds) {

					let arrCollapsibleElementIds = collapsibleElementIds.split(" ");

					for (const id of arrCollapsibleElementIds) {

						let collapsibleElement = document.getElementById(id);

						if (collapsibleElement) {

							let collapsibleElementStatus = collapsibleElement.getAttribute('aria-expanded').toLowerCase();

							if (collapsibleElementStatus === 'true') {

								that.hide(targetElement, collapsibleElement, arrRecalculateScrollForIds.includes(id));

							} else {

								that.show(targetElement, collapsibleElement, arrRecalculateScrollForIds.includes(id));

							}
						}
					}
				}
			}
		};

		let arrCollapsibles = document.querySelectorAll('.gb-collapsible__control');

		for(let i = 0; i < arrCollapsibles.length; i++) {

			arrCollapsibles[i].addEventListener('click', collapsibleClick, false);

		}
	}
}