/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUIStickyList from './gb-ui-sticky-list';

export default class GBUIDynamicBreadcrumbs extends GBUIBase {

	constructor(container) {

		super();

		this.container = container;
		this.breadcrumbsElement = this.initContainer();
		this.objStickyList = new GBUIStickyList(this.container, '.gb-info-tree__branch');

		this.initEvents();

	}

	getDataLevelSelector(level) {

		return '[data-level="'+ level +'"]';

	}

	isHidden() {

		return getComputedStyle(this.breadcrumbsElement).display.match('none') !== null;

	}

	initEvents() {

		let that = this;

		document.addEventListener('sticky-change', e => {

			const [branch, leaving] = [e.detail.target, e.detail.stuck];

			const targetControl = branch.querySelector('[aria-expanded][aria-controls]');
			const areaExpanded = targetControl && targetControl.getAttribute('aria-expanded') ? targetControl.getAttribute('aria-expanded') : null;

			if (areaExpanded !== 'true') {

				return;

			}

			const level = branch.getAttribute('data-level');
			const id = branch.getAttribute('id');

			const branchNameElem = branch.querySelector('.gb-info-tree__branch__name');
			if (!branchNameElem) {

				return;

			}

			const textElem = branchNameElem.querySelector('.gb-info-tree__branch__name__text');
			if (!textElem) {

				return;

			}

			const text = textElem.textContent;
			if (!text) {

				return;

			}

			if (leaving) {

				that.addCrumb(id, text, level);

			} else {

				that.removeCrumbsFromLevel(id, level);

			}

		});
	}

	initContainer() {

		let elem = document.getElementById('dynamic-breadcrumbs');

		if (!elem) {

			elem = document.createElement('div');
			elem.setAttribute('class', 'gb-dynamic-breadcrumbs invisible');

			document.body.prepend(elem);

		}

		return elem;
	}

	addCrumb(id, text, level) {

		let that = this;

		if (!that.breadcrumbsElement || this.isHidden()) {

			return;

		}

		const childrenCount = that.breadcrumbsElement.childElementCount;

		if (level != childrenCount) {

			let elem = that.breadcrumbsElement.querySelector(that.getDataLevelSelector(level));
			if (elem) {

				let dataId = elem.getAttribute('data-id');
				that.removeCrumbsFromLevel(dataId, level);

			} else {

				return;

			}
		}

		// // we need to remove some highlevel breadcrumbs
		// if (level < that.breadcrumbsElement.childElementCount) {

		// 	let elem = that.breadcrumbsElement.querySelector(that.getDataLevelSelector(level));
		// 	if (elem) {

		// 		let dataId = elem.getAttribute('data-id');
		// 		that.removeCrumbsFromLevel(dataId, level);

		// 	}
		// }

		let elem = document.createElement('a');
		elem.setAttribute('href', '#'+ id);
		elem.setAttribute('title', text);
		elem.setAttribute('class', 'gb-dynamic-breadcrumbs__crumb small');
		elem.setAttribute('data-level', level);
		elem.setAttribute('data-id', id);
		elem.textContent = text;

		that.breadcrumbsElement.append(elem);

		that.hideShowBreadcrumbs();

		return elem;

	}

	removeCrumbsFromLevel(id, level) {

		let that = this;

		if (!that.breadcrumbsElement || this.isHidden()) {

			return -1;

		}


		let elem = that.breadcrumbsElement.querySelector(that.getDataLevelSelector(level));
		if (!elem) {

			return -1;

		}

		const dataId = elem.getAttribute('data-id');
		if (dataId !== id) {

			return -1;

		}

		const childrenCount = that.breadcrumbsElement.childElementCount;

		for(let i = level; i < childrenCount; i++) {

			elem = that.breadcrumbsElement.querySelector(that.getDataLevelSelector(i));

			if (elem) {

				that.breadcrumbsElement.removeChild(elem);

			}
		}

		that.hideShowBreadcrumbs();

		return 0;

	}

	hideShowBreadcrumbs() {

		if (!this.breadcrumbsElement.childElementCount && !this.breadcrumbsElement.classList.contains('invisible')) {

			this.breadcrumbsElement.classList.add('invisible');

		} else if (this.breadcrumbsElement.childElementCount && this.breadcrumbsElement.classList.contains('invisible')) {

			this.breadcrumbsElement.classList.remove('invisible');

		}
	}
}