/*jshint esversion: 6 */

import GBUIHexagonalFixedButton from './gb-ui-hexagonal-fixed-button';

export default class GBUIToggleVisibility extends GBUIHexagonalFixedButton {

	constructor(element) {

		super(element);
		this.element = element;
		this.initEvents();

	}

	initEvents() {

		let that = this;

		const btnToggleVisibility = this.element.querySelector('[data-ui-role="toggle-visibility-button"]');
		if (btnToggleVisibility) {

			btnToggleVisibility.addEventListener('click', function() {

				document.body.classList.toggle('gb-photo-large--hidden-captions');

				const hs = document.body.classList.contains('gb-photo-large--hidden-captions');

				that.element.setAttribute('data-title', (hs ? 'Show' : 'Hide') + ' captions');

				const icon = btnToggleVisibility.querySelector('.mdc-fab__icon');
				if (icon) {

					icon.innerText = 'visibility_' + (hs ? 'off' : '');

				}
			});
		}
	}
}