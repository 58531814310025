/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import autoInit from '@material/auto-init/index';

class GBUISnackbar extends GBUIBase {

    constructor() {
        super();
    }

    onActionButtonClick(ev) {
        if (
            this.actionButtonCallback !== undefined &&
            this.actionButtonCallback instanceof Function
        ) {
            let that = this;
            setTimeout(() => that.actionButtonCallback(ev), 0);
        }
    }

    initDOM() {
        let that = this;

        this.objSnackbarElement = document.createElement('div');
        this.objSnackbarElement.setAttribute('id', 'gb-ui-snackbar');
        this.objSnackbarElement.setAttribute('class', 'mdc-snackbar mdc-snackbar--stacked');
        this.objSnackbarElement.setAttribute('data-mdc-auto-init', 'MDCSnackbar');

        this.objSnackbarSurfaceElement = document.createElement('div');
        this.objSnackbarSurfaceElement.setAttribute('class', 'mdc-snackbar__surface');

        this.objSnackbarLabelElement = document.createElement('div');
        this.objSnackbarLabelElement.setAttribute('class', 'mdc-snackbar__label');
        this.objSnackbarLabelElement.setAttribute('role', 'status');
        this.objSnackbarLabelElement.setAttribute('aria-live', 'polite');

        this.objSnackbarSurfaceElement.appendChild(this.objSnackbarLabelElement);

        this.objSnackbarActionsElement = document.createElement('div');
        this.objSnackbarActionsElement.setAttribute('class', 'mdc-snackbar__actions');

        // Action button
        this.objActionButtonElement = document.createElement('button');
        this.objActionButtonElement.setAttribute('data-mdc-auto-init', 'MDCRipple');
        this.objActionButtonElement.setAttribute('type', 'button');
        this.objActionButtonElement.setAttribute('class', 'mdc-button mdc-snackbar__action');
        this.objActionButtonElement.addEventListener('click', (ev) => that.onActionButtonClick(ev));

        this.objActionButtonRippleElement = document.createElement('div');
        this.objActionButtonRippleElement.setAttribute('class', 'mdc-button__ripple');

        this.objActionButtonLabelElement = document.createElement('span');
        this.objActionButtonLabelElement.setAttribute('class', 'mdc-button__label');
        this.objActionButtonLabelElement.textContent = 'Close';
        this.objActionButtonElement.appendChild(this.objActionButtonRippleElement);
        this.objActionButtonElement.appendChild(this.objActionButtonLabelElement);
        this.objSnackbarActionsElement.appendChild(this.objActionButtonElement);

        // Close button
        this.objButtonCloseElement = document.createElement('button');
        this.objButtonCloseElement.setAttribute('data-mdc-auto-init', 'MDCRipple');
        this.objButtonCloseElement.setAttribute('data-mdc-ripple-is-unbounded', 'data-mdc-ripple-is-unbounded');
        this.objButtonCloseElement.setAttribute('type', 'button');
        this.objButtonCloseElement.setAttribute('title', 'Close');
        this.objButtonCloseElement.setAttribute('class', 'mdc-icon-button mdc-snackbar__dismiss i');
        this.objButtonCloseElement.textContent = 'close';
        this.objSnackbarActionsElement.appendChild(this.objButtonCloseElement);

        this.objSnackbarSurfaceElement.appendChild(this.objSnackbarActionsElement);
        this.objSnackbarElement.appendChild(this.objSnackbarSurfaceElement);

        document.body.appendChild(this.objSnackbarElement);
        global.gbui.objMDCInit.initElements(this.objSnackbarElement);

        if (this.objSnackbarElement.MDCSnackbar !== undefined) {

            this.objSnackbar = this.objSnackbarElement.MDCSnackbar;

        }
    }

    open(strText = null, actionButtonText = null, actionButtonCallback = null) {

        if (this.objSnackbar === undefined) {
            this.initDOM();
        }

        if (actionButtonText === null) {
            this.objActionButtonElement.style.display = 'none';
        } else {
            this.objActionButtonElement.style.display = 'inline-block';
            this.objActionButtonElement.textContent = actionButtonText;

            this.actionButtonCallback = actionButtonCallback;
        }

        this.objSnackbar.labelText = strText;
        this.objSnackbar.open();
    }

    close(strReason = null) {

        if (this.objSnackbar === undefined) {
            return;
        }

        this.objSnackbar.close(strReason);

    }
}

export default new GBUISnackbar();
