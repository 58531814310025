/*jshint esversion: 6 */

import autoInit from '@material/auto-init/index';
// import * as base from '@material/base/index';
import * as checkbox from '@material/checkbox/index';
// import * as chips from '@material/chips/index';
import * as circularProgress from '@material/circular-progress';
import * as dialog from '@material/dialog/index';
// import * as dom from '@material/dom/index';
// import * as drawer from '@material/drawer/index';
// import * as floatingLabel from '@material/floating-label/index';
import * as formField from '@material/form-field/index';
// import * as gridList from '@material/grid-list/index';
// import * as iconToggle from '@material/icon-toggle/index';
import * as linearProgress from '@material/linear-progress/index';
import * as lineRipple from '@material/line-ripple/index';
import * as list from '@material/list/index';
import * as menu from '@material/menu/index';
import * as menuSurface from '@material/menu-surface/index';
import * as notchedOutline from '@material/notched-outline/index';
import * as radio from '@material/radio/index';
import * as ripple from '@material/ripple/index';
import * as select from '@material/select/index';
// import * as selectionControl from '@material/selection-control/index';
// import * as slider from '@material/slider/index';
import * as snackbar from '@material/snackbar/index';
// import * as switchControl from '@material/switch/index';
import * as tabBar from '@material/tab-bar/index';
// import * as tagify from '@material/tagify/index';
import * as textField from '@material/textfield/index';

// import * as toolbar from '@material/toolbar/index';
// import * as topAppBar from '@material/top-app-bar/index';

export default class MDCInit {

    constructor() {

        this.registerConstructors();
        this.initElements(document);

    }

    registerConstructors() {

        autoInit.register('MDCCheckbox', checkbox.MDCCheckbox);
        // autoInit.register('MDCChip', chips.MDCChip);
        // autoInit.register('MDCChipSet', chips.MDCChipSet);
        autoInit.register('MDCCircularProgress', circularProgress.MDCCircularProgress);
        autoInit.register('MDCDialog', dialog.MDCDialog);
        // autoInit.register('MDCDrawer', drawer.MDCDrawer);
        // autoInit.register('MDCFloatingLabel', floatingLabel.MDCFloatingLabel);
        autoInit.register('MDCFormField', formField.MDCFormField);
        autoInit.register('MDCRipple', ripple.MDCRipple);
        // autoInit.register('MDCGridList', gridList.MDCGridList);
        // autoInit.register('MDCIconButtonToggle', iconButton.MDCIconButtonToggle);
        // autoInit.register('MDCIconToggle', iconToggle.MDCIconToggle);
        autoInit.register('MDCLineRipple', lineRipple.MDCLineRipple);
        autoInit.register('MDCLinearProgress', linearProgress.MDCLinearProgress);
        autoInit.register('MDCList', list.MDCList);
        autoInit.register('MDCNotchedOutline', notchedOutline.MDCNotchedOutline);
        autoInit.register('MDCRadio', radio.MDCRadio);
        autoInit.register('MDCSnackbar', snackbar.MDCSnackbar);
        autoInit.register('MDCTabBar', tabBar.MDCTabBar);
        autoInit.register('MDCTextField', textField.MDCTextField);
        autoInit.register('MDCMenu', menu.MDCMenu);
        autoInit.register('MDCMenuSurface', menuSurface.MDCMenuSurface);
        autoInit.register('MDCSelect', select.MDCSelect);
        // autoInit.register('MDCSlider', slider.MDCSlider);
        // autoInit.register('MDCSwitch', switchControl.MDCSwitch);
        // autoInit.register('MDCTagify', tagify.MDCTagify);
        // autoInit.register('MDCToolbar', toolbar.MDCToolbar);
        // autoInit.register('MDCTopAppBar', topAppBar.MDCTopAppBar);

    }

    initElements(parentElement) {

        autoInit();

    }
}
