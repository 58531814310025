/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUIShareButtons extends GBUIBase {

	constructor() {

		super();

		this.initEvents();

	}

	shareButtonClickHandler(event) {

		let targetElement = event.target || event.srcElement;
		if (targetElement) {

			targetElement = this.getParentAnchor(targetElement);

			let buttonId = parseInt(targetElement.getAttribute('data-id'));
			if (buttonId) {

				let strHref = targetElement.getAttribute('href');

				if (strHref) {

					// Increase counter
					let counterElement = targetElement.querySelector('b');
					if (counterElement) {

						counterElement.innerHTML = parseInt(counterElement.innerHTML || 0) + 1;

					}

					// Logger request
					let data = {
						'url': window.location.href,
						'buttonId': buttonId
					};

					this.makeRequest('/log/share/', 'POST', data);

					// New window if necessary
					if (!strHref.startsWith('mailto:') && !strHref.startsWith('sms:')) {

						this.newWindow(strHref);
						ev.preventDefault();
						return false;
						
					}					
				}
			}
		}

		return true;

	}

	initEvents() {

		let arrShareButtons = document.querySelectorAll('.gb-layout__share__button');

		let shareButtonClickHandler = ev => this.shareButtonClickHandler(ev);

		for (let shareButton of arrShareButtons) {

			shareButton.addEventListener('click', shareButtonClickHandler );

		}
	}
}
