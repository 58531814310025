/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUIDialog from './../components/gb-ui-dialog';
import GBUITabs from './../components/gb-ui-tabs';

export default class GBUISignupSigninDialog extends GBUIBase {

	constructor(arrUrls, cb) {

		super();

		let that = this;

		this.formControls = {
			'signup': [
				'email',
				'password',
				'terms',
			],
			'signin': [
				'email',
				'password',
			],
		};
		
		this.arrUrls = arrUrls;
		this.cb = cb;

		this.objDialogElement = document.getElementById('signup-signin-dialog');

		// Tabs
		const objEditDialogTabBar = this.objDialogElement.querySelector('[role="tablist"]');
		const objEditDialogTabPages = this.objDialogElement.querySelector('[role="tabpanels"]');
		this.objEditDialogTabs = new GBUITabs(objEditDialogTabBar, objEditDialogTabPages, (index) => {

			const objOKButtonLabel = that.objDialogElement.querySelector('[data-ui-role="button-ok"] .mdc-button__label');
			if (objOKButtonLabel) {

				objOKButtonLabel.innerText = index === 0 ? 'Sign Up and Continue' : 'Sign In and Continue';
			}
		});


		if (this.objDialogElement) {

			this.objDialog = new GBUIDialog(this.objDialogElement, that.formControls.signup, () => {

				const iTabIndex = this.objEditDialogTabs.getActiveTabIndex();
				const sAction = iTabIndex === 0 ? 'signup' : 'signin';
				const objTabElement = that.objDialogElement.querySelector('[data-index="'+ iTabIndex +'"]');

				if (!objTabElement || that.arrUrls[sAction] === undefined || that.formControls[sAction] === undefined) {

					return;

				}

				that.objDialog.arrFormControls = that.formControls[sAction];

				// Send the info
				// 
				let objFormData = {};

				for(const sFormControlName of that.formControls[sAction]) {

					const value = that.getValue(objTabElement, sFormControlName);
					if (value) {

						objFormData[sFormControlName] = value;

					}
				}

				that.makeRequest(that.arrUrls[sAction], 'POST', objFormData, function(arrData, objErrors) {

					if (objErrors === null) {

						setTimeout(() => that.cb(arrData), 0);

						that.updateMainMenu(true);
						
						that.objDialog.close();

					} else {

						that.objDialog.showErrors(objErrors, objTabElement);

					}
				});
			}, () => that.cb(null));			
		}
	}

	updateMainMenu(bIsLoggedIn) {

		const objMenu = document.querySelector('.gb-layout__header__menu');
		if (!objMenu) {
			
			return;

		}

		const elements = {
			'main-menu-button-garage': true,
			'main-menu-button-profile': true,
			'main-menu-button-signin': false,
		};

		for(let [k, v] of Object.entries(elements)) {

			const objBtnGarage = objMenu.querySelector('[data-ui-role="'+ k +'"]');
			if (objBtnGarage) {

				objBtnGarage.classList.toggle('hidden', bIsLoggedIn !== v);

			}
		}
	}

	open() {

		if (this.objDialog !== undefined) {

			this.objDialog.open();

		}
	}
}
