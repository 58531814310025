/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUIMe from './gb-ui-me';
import GBUISignupSigninDialog from './gb-ui-signup-signin-dialog';
import GBUISnackbar from './gb-ui-snackbar';
import GLightbox from 'glightbox'

export default class GBUIFilesBase extends GBUIBase {

	/*
	 * Constructor
	 *
	 * arrEntities (array) - containers with files.
	 *  each entity is an object
	 *		type - entuty type (for upload)
	 *		id - entity id
	 *		container - files container
	 *		arrDropTargetElements - drop target elements
	 *		arrDropTargetSelectors - drop target selectors
	 *		arrClickTargetElements - array of click target elements (upload buttons)
	 *		arrClickTargetSelectors - array of click target selectors (upload buttons)
	 *		arrHideOnEmptySelectors - array of element selectors which need to be hidden when list is empty
	 *		arrShowOnEmptySelectors - array of element selectors which need to be hidden when list is empty
	 *
	 * arrUrls (array)
	 *		upload - url to upload files
	 *		me - me URL
	 * 
	 */
	constructor(arrEntities, arrUrls, accept) {

		super();

		let that = this;

		// Constants
		this.sErrorMessage = "An error occurred, please try again later.";
		this.sErrorMessageFileIsToLarge = "We only support %fileExt% files up to %fileSize% Mb, please upload smaller file.";
		this.sErrorMessageFileIsUnsupported = "We don't know how to process this file type, please upload another file.";
		this.sErrorMessageCantLoadUrl = "Can't load this image, please try again later.";
		this.sCloseWarning = "Images are still uploading. Do you want to cancel uploading?";
		
		this.types = {
			'image/jpeg': {
				'maxFileSize': 31457280, // 30 MB
				'extension': 'jpg'
			},
			'image/webp': {
				'maxFileSize': 10485760, // 10 MB
				'extension': 'webp'
			},
			'image/tiff': {
				'maxFileSize': 52428800, // 50 MB
				'extension': 'tiff'
			},
			'image/gif': {
				'maxFileSize': 5242880, // 5 MB
				'extension': 'gif'
			},
			'image/png': {
				'maxFileSize': 10485760, // 10 MB
				'extension': 'png'
			},
			'image/svg+xml': {
				'maxFileSize': 1048576, // 1 MB
				'extension': 'svg'
			},
			'application/pdf': {
				'maxFileSize': 52428800, // 50 MB
				'extension': 'pdf'
			}
		};

		// params
		this.mapEntities = new Map();
		this.arrUrls = arrUrls;
		
		// helpers
		this.objSnackbar = GBUISnackbar;
		this.mapWorkers = new Map();

		// elements
		this.objVirtualInput = document.createElement('input');
		if (this.objVirtualInput) {

			this.objVirtualInput.type = 'file';
			this.objVirtualInput.multiple = true;
			this.objVirtualInput.accept = accept;
			this.objVirtualInput.addEventListener("change", (objEvent) => { that.onFileChange(objEvent); });

		}

		this.assignEntities(arrEntities);

		window.addEventListener('beforeunload', (e) => that.onBeforeUnload(e));

	}

	onBeforeUnload(e) {

		if (this.mapWorkers.size === 0) return;


		e = e || window.event;

		if (e) {

			e.returnValue = this.sCloseWarning;

		}

		return this.sCloseWarning;

	}

	createUniqueWorkerId() {

		const uidGen = require('gen-uid');
		let uid = uidGen.v4();

		while (this.mapWorkers.has(uid)) {

			uid = uidGen.v4();

		}

		return uid;
	}

	// 
	// Shows snackbar with specified text
	//
	showSnackbar(str = null) {

		if (this.objSnackbar && str) {

			this.objSnackbar.open(str);

		}
	}

	// 
	// Checks and assigns file elements object
	//
	assignEntities(arrEntities) {

		this.mapEntities.clear();

		for(const objEntity of arrEntities) {

			// type
			// id
			// container
			// arrDropTargetElements
			// arrDropTargetSelectors
			// arrClickTargetElements
			// arrClickTargetSelectors

			if (
				objEntity.type !== undefined &&
				objEntity.id !== undefined &&
				objEntity.container !== undefined
			) {

				const sEntityKey = objEntity.type +'.'+ objEntity.id;

				this.mapEntities.set(sEntityKey, objEntity);

				this.initEntityEvents(sEntityKey, objEntity);

			}
		}
	}

	initOnDropEvent(objElement, sEntityKey) {

		let that = this;

		const funcPreventDefault = function(objEvent) {

			objEvent.preventDefault();
			objEvent.stopPropagation();

		};

		objElement.setAttribute('data-entity-key', sEntityKey);

		objElement.addEventListener('dragenter', funcPreventDefault);
		objElement.addEventListener('dragleave', funcPreventDefault);
		objElement.addEventListener('dragover', funcPreventDefault);
		objElement.addEventListener('drop', (objEvent) => { that.onDrop(objEvent); });

	}

	initClickEvent(objElement, sEntityKey) {

		let that = this;

		objElement.setAttribute('data-entity-key', sEntityKey);
		
		objElement.addEventListener('click', (objEvent) => { 

			const sEntityKey = objEvent.currentTarget.getAttribute('data-entity-key');
			that.objVirtualInput.setAttribute('data-entity-key', sEntityKey);
			that.objVirtualInput.click(objEvent);

		});
	}

	// 
	// Init events for file elements
	//
	initEntityEvents(sEntityKey, objEntity) {

		// Init drop events
		this.initOnDropEvent(objEntity.container, sEntityKey);

		if (objEntity.arrDropTargetElements !== undefined) {

			for(const objElem of objEntity.arrDropTargetElements) {

				this.initOnDropEvent(objElem, sEntityKey);

			}
		}

		if (objEntity.arrDropTargetSelectors !== undefined) {

			for(const strSelector of objEntity.arrDropTargetSelectors) {

				const arrElements = objEntity.container.querySelectorAll(strSelector);

				if (arrElements) {

					for(const objElem of arrElements) {

						this.initOnDropEvent(objElem, sEntityKey);

					}
				}
			}
		}

		// Init click events
		if (objEntity.arrClickTargetElements !== undefined) {

			for(const objClickTarget of objEntity.arrClickTargetElements) {

				this.initClickEvent(objClickTarget, sEntityKey);

			}
		}

		if (objEntity.arrClickTargetSelectors !== undefined) {

			for(const strSelector of objEntity.arrClickTargetSelectors) {

				const arrElements = objEntity.container.querySelectorAll(strSelector);

				if (arrElements) {

					for(const objElem of arrElements) {

						this.initClickEvent(objElem, sEntityKey);

					}
				}
			}
		}
	}

	// 
	// On drop event handler
	//
	onDrop(objEvent) {

		objEvent.preventDefault();
		objEvent.stopPropagation();

		const sEntityKey = objEvent.currentTarget.getAttribute('data-entity-key');

		if (!this.mapEntities.has(sEntityKey)) {

			return;

		}

		const objDataTransfer = objEvent.dataTransfer;

		if (!objDataTransfer) {

			return;

		}

		const objEntity = this.mapEntities.get(sEntityKey);

		if (objDataTransfer.files && objDataTransfer.files.length) {

			this.processFiles(objEntity, objDataTransfer.files);

		} else {

			const strHtml = objDataTransfer.getData('text/html'), 
				arrRegRes = strHtml && /\s+src="?([^"]+)"?/.exec(strHtml),
				strUrl = arrRegRes && arrRegRes[1];

			if (strUrl) {

				this.uploadFromUrl(objEntity, strUrl);

			}
		}
	}

	// 
	// On change element handler for file input
	//
	onFileChange() {

		const sEntityKey = this.objVirtualInput.getAttribute('data-entity-key');

		if (sEntityKey && this.mapEntities.has(sEntityKey)) {

			this.processFiles(this.mapEntities.get(sEntityKey), this.objVirtualInput.files)
			
		}
	}

	// 
	// Uploads image from url
	//
	uploadFromUrl(objEntity, sUrl) {

		let that = this;
		let objImage = new Image();

		if (!objImage) {

			return;

		}

		let objCanvas = document.createElement("canvas");

		if (!objCanvas) {

			return;

		}

		let objContext = objCanvas.getContext("2d");

		if (!objContext) {

			return;

		}

		objImage.onload = function() {
			objCanvas.width = this.naturalWidth;
			objCanvas.height = this.naturalHeight;
			objContext.drawImage(this, 0, 0);
			objCanvas.toBlob(function(pngBlob) { that.processFiles(objEntity, [pngBlob]); }, "image/png");
		};
		objImage.onerror = function() {
			that.showSnackbar(that.sErrorMessageCantLoadUrl);
		}
		objImage.crossOrigin = "";
		objImage.src = sUrl;

	}

	createWorker(objEntity, sWorkerUid, file) {

		let that = this;
		
		let objFormData = new FormData();
		objFormData.append('id', sWorkerUid);
		objFormData.append('entity_id', objEntity.id);
		objFormData.append('entity_type', objEntity.type);
		objFormData.append('file', file);

		let objRequest = new XMLHttpRequest();
		objRequest.open("POST", this.arrUrls['upload'], true);
		objRequest.onreadystatechange = () => {

			if (objRequest.readyState === XMLHttpRequest.DONE) {

				if (objRequest.status === 200) {

					const data = JSON.parse(objRequest.responseText);
					that.updateFileElement(objEntity, sWorkerUid, 100, data.data || null);
					
				} else if (objRequest.status !== 0) {
					
					// Show error
					that.showSnackbar(that.sErrorMessage);
					that.updateFileElementError(objEntity, sWorkerUid);

				}

				that.mapWorkers.delete(sWorkerUid);

			}
		}
		objRequest.upload.onprogress = function(e) {

			that.updateFileElement(objEntity, sWorkerUid, 100 - ((e.loaded / e.total * 100) || 100));

		}

		objRequest.send(objFormData);

		return objRequest;

	}

	// 
	// Terminates worker and stops uploading
	//
	terminateWorker(workerId) {

		const objWorker = this.mapWorkers.get(workerId);

		if (objWorker) {

			objWorker.abort();

			this.removeWorker(workerId);

		}
	}

	// 
	// Removes worker from the list
	//
	removeWorker(workerId) {

		this.mapWorkers.delete(workerId);

	}

	// 
	// Validates file
	//
	validateFile(file) {

		if (!this.objHasProperty(file, 'type')) {

			this.showSnackbar(this.sErrorMessage);
			return false;

		}

		if (!this.objHasProperty(this.types, file.type)) {

			this.showSnackbar(this.sErrorMessageFileIsUnsupported);
			return false;

		}

		if (file.size > this.types[file.type].maxFileSize) {

			this.showSnackbar(
				this.sErrorMessageFileIsToLarge.
					replace('%fileSize%', Math.round(this.types[file.type].maxFileSize / 1048576)).
					replace('%fileExt%', this.types[file.type].extension)
			);
			return false;

		}

		return true;

	}

	// 
	// Checks that file is an image
	//
	isImage(file) {

		const arrImageTypes = ['image/jpeg', 'image/webp', 'image/tiff', 'image/gif', 'image/png', 'image/svg+xml'];
		return arrImageTypes.indexOf(file.type) >= 0;

	}

	// 
	// Uploads array of files
	//
	uploadFiles(objEntity, arrFiles) {

		for(const file of arrFiles) {

			if (this.validateFile(file)) {

				const sWorkerUid = this.createUniqueWorkerId();

				if (this.createFileElement(objEntity, sWorkerUid, file)) {

					// worker uid
					this.mapWorkers.set(sWorkerUid, this.createWorker(objEntity, sWorkerUid, file));

				}
			}
		}
		
		this.listChanges(objEntity);
		this.objVirtualInput.value = null;

	}

	// 
	// Uploads files if user is logged in,
	// shows login dialog otherwise
	//
	processFiles(objEntity, arrFiles) {

		let that = this;

		// Check if user is registered
		const me = new GBUIMe(this.arrUrls['me'], (arrData, objErrors) => {

			if (
				objErrors === null &&
				arrData.user !== undefined &&
				arrData.user !== null &&
				typeof arrData.user === 'object' &&
				arrData.user.id !== undefined &&
				parseInt(arrData.user.id) > 0
			) {

				that.uploadFiles(objEntity, arrFiles);

			} else {

				const objQuickSignupDialog = new GBUISignupSigninDialog(that.arrUrls, (arrData) => {

					if (
						arrData && 
						arrData.user !== undefined &&
						arrData.user !== null &&
						typeof arrData.user === 'object' &&
						arrData.user.id !== undefined &&
						parseInt(arrData.user.id) > 0
					) {

						that.uploadFiles(objEntity, arrFiles);

					} else {

						this.objVirtualInput.value = null;

					}
				});

				objQuickSignupDialog.open();

			}
		});
	}

	// 
	// Uploads array of files
	//
	deleteFile(objEntity, mediaEntityId = null, sWorkerUid = null) {

		// Remove worker
		if (
			sWorkerUid !== null &&
			this.mapWorkers.has(sWorkerUid)
		) {

			this.terminateWorker(sWorkerUid);

		}

		// Remove media entity record
		if (mediaEntityId) {

			let data = {
				'id': mediaEntityId
			};

			const url = this.arrUrls['upload'] +'?'+ this.encodeQueryData(data);

			this.makeRequest(url, 'DELETE');

		}

		this.removeFileElement(objEntity, mediaEntityId, sWorkerUid);
		this.listChanges(objEntity);

	}

	// 
	// Removes file element from the list
	//
	removeFileElement(objEntity, mediaEntityId, sWorkerUid) {

		return true;

	}

	// 
	// Creates file element in the list
	//
	createFileElement(objEntity, sWorkerUid, file) {

		return true;

	}

	// 
	// Updates file element
	//
	updateFileElement(objEntity, sWorkerUid, iProgress = 0, objResult = null, objError = null) {

		return true;

	}

	listChanges(objEntity) {

		return true;

	}
}
