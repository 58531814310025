/*jshint esversion: 6 */

import GbUiCartBase from "./gb-ui-cart-base";
import {MDCMenu} from '@material/menu';
import GBUIMainMenuCartIcon from './gb-ui-main-menu-cart-icon';

export default class GBUIAddToCartVertical extends GbUiCartBase {

    constructor(objContainer, arrUrls) {

        super(arrUrls);

        this.arrFormControls = [
            'qty',
            'part_id',
        ];

        this.objContainer = objContainer;
        this.cartId = objContainer.getAttribute('data-cart-id');
        this.objCartIcon = GBUIMainMenuCartIcon;

        this.initEvents();

    }

    addToShoppingList(objButton, shoppingListUrl, shoppingListName = null) {

        let that = this;

        // Send the info
        //
        let objFormData = that.getFormData();

        that.buttonActionLoading(objButton);

        that.makeRequest(that.getAddToShoppingListUrl(shoppingListUrl), 'PUT', objFormData, function(arrData, objErrors) {

            that.buttonActionLoadingSuccess(objButton);

            if (objErrors === null) {

                if (shoppingListName === null) {

                    that.objCartIcon.full();
                    that.objSnackbar.open("Item has been added to your cart.", "Visit your cart", () => { window.location = that.arrUrls.cart; });

                } else {

                    that.objSnackbar.open("Item has been added to shopping list „"+ shoppingListName +"“.", "Visit shopping list", () => { window.location = that.getShoppingListUrl(shoppingListUrl); });

                }

            } else {

                that.objSnackbar.open("An error occurred. Please try again later.");

            }
        });
    }

    initEvents() {

        let that = this;

        const objShoppingListMenu = this.objContainer.querySelector('[data-ui-role="shopping-list-menu"]');
        const objAddToCartBtn = this.objContainer.querySelector('[data-ui-role="add-to-cart-btn"]');
        const objChooseShoppingListBtn = this.objContainer.querySelector('[data-ui-role="choose-shopping-list-btn"]');

        if (objAddToCartBtn) {

            objAddToCartBtn.addEventListener('click', (ev) => {

                that.addToShoppingList(objAddToCartBtn, 'cart');
				ev.preventDefault();
                return false;

            });
        }

        // Pick shopping list menu
        if (objChooseShoppingListBtn && objShoppingListMenu) {

            objChooseShoppingListBtn.addEventListener('click', () => {

                const menu = new MDCMenu(objShoppingListMenu);
                if (!menu) {

                    return;

                }

                const menuItemClickListener = (ev) => {

                    if (
                        !this.objHasProperty(ev, 'detail') ||
                        !this.objHasProperty(ev.detail, 'item')
                    ) {

                        menu.unlisten('MDCMenu:selected', menuItemClickListener);
                        menu.destroy();
                        return;

                    }

                    const dataUrl = ev.detail.item.getAttribute('data-url');

                    if (dataUrl === null) {

                        this.objCreateShoppingListDialog.open(that.arrUrls.apiCreateShoppingList, (objShoppingList) => {

                            if (objShoppingList === null) {

                                return false;

                            }

                            const objMenu = that.objContainer.querySelector('[data-ui-role="shopping-list-menu"] .mdc-list');
                            if (objMenu) {

                                that.addShoppingListToMenu(objMenu, objShoppingList.name, objShoppingList.url, objShoppingList.id);

                            }
                            that.addToShoppingList(objChooseShoppingListBtn, objShoppingList.url, objShoppingList.name);

                            return  true;

                        });

                    } else {

                        const textItem = ev.detail.item.querySelector('.mdc-list-item__text');
                        if (textItem) {

                            that.addToShoppingList(objChooseShoppingListBtn, dataUrl, textItem.textContent);

                        }
                    }

                    menu.unlisten('MDCMenu:selected', menuItemClickListener);
                    menu.destroy();
                };

                menu.setIsHoisted(true);
                menu.setAnchorCorner(1);
                menu.setAnchorElement(objChooseShoppingListBtn);
                menu.listen('MDCMenu:selected', menuItemClickListener);
                menu.open = true;

            }, false);
        }
    }
}
