/*jshint esversion: 6 */

import * as checkbox from '@material/checkbox/index';
import * as radio from '@material/radio/index';
import * as select from '@material/select/index';
import * as switchControl from '@material/switch/index';
import * as tagify from '@material/tagify/index';
import * as textField from '@material/textfield/index';

export default class GBUIBase {

    constructor() {
    }

    copyToClipboard(str, container = document.body) {

        const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

        let tmpElem = document.createElement('textarea');
        // Prevent zooming on iOS
        tmpElem.style.fontSize = '12pt';
        // Reset box model
        tmpElem.style.border = '0';
        tmpElem.style.padding = '0';
        tmpElem.style.margin = '0';
        // Move element out of screen horizontally
        tmpElem.style.position = 'absolute';
        tmpElem.style[isRTL ? 'right' : 'left'] = '-9999px';
        // Move element to the same position vertically
        let yPosition = window.pageYOffset || document.documentElement.scrollTop;
        tmpElem.style.top = `${yPosition}px`;

        tmpElem.setAttribute('readonly', '');
        tmpElem.value = str;

        container.appendChild(tmpElem);

        tmpElem.select();
        tmpElem.setSelectionRange(0, tmpElem.value.length);

        let succeeded = false;

        try {

            succeeded = document.execCommand('copy');

        } catch (err) {
        }

        container.removeChild(tmpElem);

        return succeeded;

    }

    //
    // Checks that obj is an object
    //
    isObj(obj) {

        return obj !== undefined && obj !== null && typeof obj === 'object';
    }

    //
    // Checks that obj is an object and it has a property named prop
    //
    objHasProperty(obj, prop) {

        return this.isObj(obj) && obj[prop] !== undefined;
    }

    makeRequest(url, method, data, callback) {

        if (url === undefined || !url || method === undefined || !['POST', 'PUT', 'GET', 'DELETE'].includes(method)) return;

        let httpRequest = new XMLHttpRequest();
        if (!httpRequest) return;

        httpRequest.onreadystatechange = function () {

            try {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {

                    let data = JSON.parse(httpRequest.responseText);

                    if (callback instanceof Function) {

                        callback(data.data || null, data.errors || null, data.html || null);

                    }
                    // callback(data.data !== undefined ? data.data : null, data.html !== undefined ? data.html : null, data.errors !== undefined ? data.errors : null);

                }

            } catch (e) {

                console.log('Caught Exception: ' + e);

            }
        };

        let dataEncoded = null;

        httpRequest.open(method, url, true);
        if (['POST', 'PUT'].includes(method)) {

            httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            if (data !== undefined) dataEncoded = JSON.stringify(data);

        }

        httpRequest.send(dataEncoded);
    }

    setCookie(cName, cValue, exDays) {

        let d = new Date();
        d.setTime(d.getTime() + exDays * 86400000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cName + "=" + cValue + "; path=/; " + expires + "; secure";
    }


    isIterable(obj) {
        // checks for null and undefined
        if (obj === null || obj === undefined) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }

    strMapToObj(strMap) {
        let obj = Object.create(null);
        for (let [k, v] of strMap) {
            // We don’t escape the key '__proto__'
            // which can cause problems on older engines
            obj[k] = v;
        }
        return obj;
    }

    encodeQueryData(data) {

        let ret = [];
        for (const d in data) {

            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));

        }
        return ret.join('&');
    }

    objToStrMap(obj) {
        let strMap = new Map();
        for (let k of Object.keys(obj)) {
            strMap.set(k, obj[k]);
        }
        return strMap;
    }

    // removeElementById(id) {

    // 	let elem = document.getElementById(id);
    // 	return elem.parentNode.removeChild(elem);

    // }

    htmlToElement(html) {

        let template = document.createElement('template');
        template.innerHTML = html.trim();
        return template.content.firstChild;

    }

    getParentAnchor(target) {

        while (target) {

            if (target instanceof HTMLAnchorElement) {

                break;

            }

            target = target.parentNode;

        }

        return target;

    }

    newWindow(url) {

        let intScreenWidth = screen && screen.width ? screen.width : 0;
        let intScreenHeight = screen && screen.height ? screen.height : 0;
        let intWindowWidth = window && window.innerWidth ? window.innerWidth : 0;
        let intWindowHeight = window && window.innerHeight ? window.innerHeight : 0;
        if (intWindowWidth >= 1300) intWindowWidth -= 800;
        if (intWindowHeight >= 800) intWindowHeight -= 400;

        let intLeft = 0;
        let intTop = 0;

        if (intScreenWidth > 0 && intScreenHeight > 0) {

            intLeft = Math.round((intScreenWidth - intWindowWidth) / 2);
            intTop = Math.round((intScreenHeight - intWindowHeight) / 2);

        }

        window.open(url, 'Thank you for sharing :)', 'width=' + intWindowWidth + ',height=' + intWindowHeight + ',left=' + intLeft + ',top=' + intTop + ',location=no,toolbar=no,directories=no,scrollbars=yes');

    }

    collapseSection(element) {

        // get the height of the element's inner content, regardless of its actual size
        const sectionHeight = element.scrollHeight;

        // temporarily disable all css transitions
        const elementTransition = element.style.transition;
        element.style.transition = '';

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(function () {
            element.style.height = sectionHeight + 'px';
            element.style.transition = elementTransition;

            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(function () {
                element.style.height = 0 + 'px';
            });
        });

        // mark the section as "currently collapsed"
        element.setAttribute('aria-expanded', 'false');
    }

    expandSection(element) {
        // get the height of the element's inner content, regardless of its actual size
        const sectionHeight = element.scrollHeight;

        // have the element transition to the height of its inner content
        element.style.height = sectionHeight + 'px';

        // when the next css transition finishes (which should be the one we just triggered)
        element.addEventListener('transitionend', () => {

            // remove this event listener so it only gets triggered once
            // element.removeEventListener('transitionend', arguments.callee);

            // remove "height" from the element's inline styles, so it can return to its initial value
            element.style.height = null;
        }, {
            once: true
        });

        // mark the section as "currently not collapsed"
        element.setAttribute('aria-expanded', 'true');
    }

    setAttributesFromObject(obj, attributes = null) {

        if (attributes) {
            for (let key in attributes) {
                if (attributes.hasOwnProperty(key)) {
                    obj.setAttribute(key, attributes[key]);
                }
            }
        }
    }

    appendOptionToNativeSelect(objSelectElement, value = '', text = '', attributes = null) {

        let objOptionElement = objSelectElement.querySelector('option[value="' + value + '"]');
        if (!objOptionElement) {

            objOptionElement = document.createElement("option");
            objOptionElement.text = text;
            objOptionElement.value = value;
            this.setAttributesFromObject(objOptionElement, attributes);
            objSelectElement.appendChild(objOptionElement);

        }
    }

    appendOptionToMDCSelect(objMDCSelect, value = '', text = '', attributes = null) {

        if (!objMDCSelect.menuElement) return;

        const objMDCListElement = objMDCSelect.menuElement.querySelector('.mdc-list');
        if (!objMDCListElement) return;

        let objOptionElement = document.createElement('li');

        objOptionElement.setAttribute('class', 'mdc-list-item');
        // objOptionElement.setAttribute('value', value);
        objOptionElement.setAttribute('data-value', value);
        objOptionElement.setAttribute('tabindex', '-1');
        this.setAttributesFromObject(objOptionElement, attributes);

        let objOptionTextElement = document.createElement('span');
        objOptionTextElement.setAttribute('class', 'mdc-list-item__text');
        objOptionTextElement.textContent = text;

        objOptionElement.appendChild(objOptionTextElement);

        objMDCListElement.appendChild(objOptionElement);

    }

    appendOptionToSelect(objForm, sFormControlName, value = '', text = '', $attributes = null) {

        // Get form control native element
        const objFormControl = objForm.querySelector('[name="' + sFormControlName + '"]');

        if (objFormControl) {

            // Check if we have a MDC wrapper around native control
            const objMDCControl = this.getMDCControlForFormControl(objFormControl);

            if (objMDCControl) {

                this.appendOptionToMDCSelect(objMDCControl, value, text, $attributes);

            } else {

                this.appendOptionToNativeSelect(objFormControl, value, text, $attributes);

            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const objMDCFormControl = objForm.querySelector('[data-name="' + sFormControlName + '"]');

        if (objMDCFormControl) {

            const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

            if (objMDCControl) {

                this.appendOptionToMDCSelect(objMDCControl, value, text, $attributes);

            }

            return true;

        }

        return false;

    }

    removeAllOptionsFromNativeSelect(objSelectElement) {

        objSelectElement.options.length = 0;

    }

    removeAllOptionsFromMDCSelect(objMDCSelect) {

        if (objMDCSelect.menuElement) {

            const objMDCListElement = objMDCSelect.menuElement.querySelector('.mdc-list');

            if (objMDCListElement) {

                while (objMDCListElement.firstChild) {

                    objMDCListElement.removeChild(objMDCListElement.firstChild);

                }
            }
        }
    }

    removeAllOptionsFromSelect(objForm, sFormControlName) {

        // Get form control native element
        const objFormControl = objForm.querySelector('[name="' + sFormControlName + '"]');

        if (objFormControl) {

            // Check if we have a MDC wrapper around native control
            const objMDCControl = this.getMDCControlForFormControl(objFormControl);

            if (objMDCControl) {

                this.removeAllOptionsFromMDCSelect(objMDCControl);

            } else {

                this.removeAllOptionsFromNativeSelect(objFormControl);

            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const objMDCFormControl = objForm.querySelector('[data-name="' + sFormControlName + '"]');

        if (objMDCFormControl) {

            const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

            if (objMDCControl) {

                this.removeAllOptionsFromMDCSelect(objMDCControl);

            }

            return true;

        }

        return false;

    }

    getOptionAttribute(objForm, sFormControlName, sAttributeName) {

        // Get form control native element
        const objFormControl = objForm.querySelector('[name="' + sFormControlName + '"]');

        if (objFormControl) {

            // Check if we have a MDC wrapper around native control
            const objMDCControl = this.getMDCControlForFormControl(objFormControl);

            if (objMDCControl && objMDCControl instanceof select.MDCSelect && objMDCControl.menuElement !== undefined) {

                const arrSelectedItems = objMDCControl.menuElement.querySelectorAll('.mdc-list .mdc-list-item.mdc-list-item--selected');

                if (arrSelectedItems.length) {

                    const objMenuElement = arrSelectedItems[arrSelectedItems.length - 1];

                    if (objMenuElement) {

                        return objMenuElement.getAttribute(sAttributeName);

                    }
                }

            } else {

                // this.removeAllOptionsFromNativeSelect(objFormControl);
                if (objFormControl.options !== undefined) {

                    return objFormControl.options[objFormControl.selectedIndex].getAttribute(sAttributeName);

                }
            }
        }

        // Maybe we have completely custom form element without native controls
        const objMDCFormControl = objForm.querySelector('[data-name="' + sFormControlName + '"]');

        if (objMDCFormControl) {

            const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

            if (objMDCControl && objMDCControl instanceof select.MDCSelect && objMDCControl.menuElement !== undefined) {

                const arrSelectedItems = objMDCControl.menuElement.querySelectorAll('.mdc-list .mdc-list-item.mdc-list-item--selected');

                if (arrSelectedItems.length) {

                    const objMenuElement = arrSelectedItems[arrSelectedItems.length - 1];

                    if (objMenuElement) {

                        return objMenuElement.getAttribute(sAttributeName);

                    }
                }
            }
        }

        return null;

    }

    disableElement(objForm, sFormControlName, bDisabled = true) {

        // Get form control native element
        const objFormControl = objForm.querySelector('[name="' + sFormControlName + '"]');

        if (objFormControl) {

            // Check if we have a MDC wrapper around native control
            const objMDCControl = this.getMDCControlForFormControl(objFormControl);

            if (objMDCControl) {

                objMDCControl.disabled = bDisabled;

            } else {

                objFormControl.disabled = bDisabled;

            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const objMDCFormControl = objForm.querySelector('[data-name="' + sFormControlName + '"]');

        if (objMDCFormControl) {

            const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

            if (objMDCControl) {

                objMDCControl.disabled = bDisabled;

            }

            return true;

        }

        return false;

    }

    getFormControlElement(objForm, sFormControlName) {

        // Get form control native element
        const objFormControl = objForm.querySelector('[name="' + sFormControlName + '"]');

        if (objFormControl) {

            return objFormControl;

        }

        // Maybe we have completely custom form element without native controls
        const objMDCFormControl = objForm.querySelector('[data-name="' + sFormControlName + '"]');

        if (objMDCFormControl) {

            return objMDCFormControl;

        }

        return null;

    }

    getMDCControlForElement(objElement) {

        // Check if element has MDC class name reference
        const sMDCClassName = objElement.getAttribute('data-mdc-auto-init');
        if (sMDCClassName) {

            // Check if MDC component has been initialized
            const bMDCClassInitialized = objElement.getAttribute('data-mdc-auto-init-state') === 'initialized';
            if (bMDCClassInitialized) {

                // Check if we have a MDC object attached to the DOM element
                if (objElement[sMDCClassName] !== undefined) {

                    return objElement[sMDCClassName];

                }
            }
        }

        return null;

    }

    getMDCControlForFormControl(objFormControl) {

        if (objFormControl && objFormControl.parentElement) {

            // Check if we have a MDC wrapper around native control
            const objMDCControl = objFormControl.parentElement;
            if (objMDCControl && objMDCControl.hasAttribute('data-mdc-auto-init')) {

                return this.getMDCControlForElement(objMDCControl);

            } else if (objMDCControl.parentElement && objMDCControl.parentElement.hasAttribute('data-mdc-auto-init')) {

                return this.getMDCControlForElement(objMDCControl.parentElement);

            }
        }

        return null;

    }

    setMDCControlValue(objMDCControl, value = '') {

		if (objMDCControl instanceof checkbox.MDCCheckbox) {

			if (value) {

				if (Array.isArray(value)) {

					objMDCControl.checked = value.includes(objMDCControl.value);

				} else {

					objMDCControl.checked = value == objMDCControl.value;

				}

			} else {

				objMDCControl.checked = false;

			}

			return true;

		} else if (objMDCControl instanceof radio.MDCRadio) {

			objMDCControl.checked = value ? objMDCControl.value == value : false;
			return true;

		} else if (objMDCControl instanceof switchControl.MDCSwitch) {

			objMDCControl.checked = Boolean(value);
			return true;

		} else if (objMDCControl instanceof tagify.MDCTagify) {

			objMDCControl.removeAllTags();
			objMDCControl.tags = value;
			return true;

		} else if (objMDCControl && objMDCControl.constructor !== undefined) {

			objMDCControl.value = value ? value + '' : '';
			return true;

		}

        return false;

    }

    setNativeControlValue(objFormControl, value = '') {

        if (objFormControl && objFormControl.getAttribute) {

            const sFormControlType = objFormControl.getAttribute('type').toLowerCase();

            switch (sFormControlType) {

                case 'checkbox':
                case 'radio':
                    objFormControl.checked = value ? objFormControl.value == value : false;
                    break;

                default:
                    objFormControl.value = value ? value : '';
                    break;
            }

            return true;

        }

        return false;

    }

    setValue(objForm, sFormControlName, value = '') {

        // Get form control native element
        const arrFormControls = objForm.querySelectorAll('[name="' + sFormControlName + '"]');
        if (arrFormControls.length) {

            for (const objFormControl of arrFormControls) {

                // Check if we have a MDC wrapper around native control
                const objMDCControl = this.getMDCControlForFormControl(objFormControl);

                if (objMDCControl) {

                    this.setMDCControlValue(objMDCControl, value);

                } else {

                    this.setNativeControlValue(objFormControl, value);

                }
            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const arrMDCFormControls = objForm.querySelectorAll('[data-name="' + sFormControlName + '"]');

        if (arrMDCFormControls.length) {

            for (const objMDCFormControl of arrMDCFormControls) {

                const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

                if (objMDCControl) {

                    this.setMDCControlValue(objMDCControl, value);

                }
            }

            return true;

        }

        return false;

    }

    getMDCControlValue(objMDCControl) {

		if (
			objMDCControl instanceof checkbox.MDCCheckbox ||
			objMDCControl instanceof radio.MDCRadio
		) {

			let valueOff = null;
			if (objMDCControl.nativeControl_.hasAttribute('data-value-off')) {
				valueOff = objMDCControl.nativeControl_.getAttribute('data-value-off');
			}
			return objMDCControl.checked ? objMDCControl.value || valueOff : valueOff;

		} else if (objMDCControl instanceof switchControl.MDCSwitch) {

			return objMDCControl.checked ? 1 : 0;

		} else if (objMDCControl instanceof tagify.MDCTagify) {

			return objMDCControl.tags;

		} else if (objMDCControl && objMDCControl.constructor !== undefined) {

			return objMDCControl.value || null;

		}

        return null;

    }

    getNativeControlValue(objFormControl) {

        if (objFormControl && objFormControl.getAttribute) {

            const sFormControlType = objFormControl.getAttribute('type').toLowerCase();

            switch (sFormControlType) {

                case 'checkbox':
                case 'radio':
                    let valueOff = null;
                    if (objFormControl.hasAttribute('data-value-off')) {
                        valueOff = objFormControl.getAttribute('data-value-off');
                    }
                    return objFormControl.checked ? objFormControl.value || valueOff : valueOff;

            }

            return objFormControl.value || null;

        }

        return null;

    }

    getValue(objForm, sFormControlName) {

        let value = null;

        // Get form control native element
        const arrFormControls = objForm.querySelectorAll('[name="' + sFormControlName + '"]');
        if (arrFormControls.length) {

            const isArray = arrFormControls.length > 1;

            if (isArray) {

                value = [];

            }

            for (const objFormControl of arrFormControls) {

                // Check if we have a MDC wrapper around native control
                const objMDCControl = this.getMDCControlForFormControl(objFormControl);

                let v = null;

                if (objMDCControl) {

                    v = this.getMDCControlValue(objMDCControl);

                } else {

                    v = this.getNativeControlValue(objFormControl);

                }

                if (v !== null) {

                    if (isArray) {

                        value.push(v);

                    } else {

                        value = v;

                    }
                }
            }

            return value;

        }

        // Maybe we have completely custom form element without native controls
        const arrMDCFormControls = objForm.querySelectorAll('[data-name="' + sFormControlName + '"]');

        if (arrMDCFormControls.length) {

            const isArray = arrMDCFormControls.length > 1;

            if (isArray) {

                value = [];

            }

            for (const objMDCFormControl of arrMDCFormControls) {

                const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

                if (objMDCControl) {

                    const v = this.getMDCControlValue(objMDCControl);

                    if (v !== null) {

                        if (isArray) {

                            value.push(v);

                        } else {

                            value = v;

                        }
                    }
                }
            }
        }

        return value;

    }

    setMDCControlValid(objMDCControl) {

		if (
			objMDCControl instanceof checkbox.MDCCheckbox ||
			objMDCControl instanceof radio.MDCRadio
		) {

			if (objMDCControl.nativeControl_) {

				if (objMDCControl.nativeControl_.hasAttribute('aria-controls')) {

					const sErrorLabelId = objMDCControl.nativeControl_.getAttribute('aria-controls');

					if (sErrorLabelId) {

						const sErrorLabelElement = document.getElementById(sErrorLabelId);
						if (sErrorLabelElement) {

							sErrorLabelElement.innerText = '';
							// sErrorLabelElement.classList.remove('mdc-select-helper-text--persistent');

						}
					}
				}
			}

			return true;

		} else if (
			objMDCControl instanceof checkbox.MDCCheckbox ||
			objMDCControl instanceof tagify.MDCTagify ||
			objMDCControl instanceof textField.MDCTextField
		) {

			objMDCControl.valid = true;
			objMDCControl.helperTextContent = '';

			return true;

		}

        return false;

    }

    setMDCControlInvalid(objMDCControl, sErrors) {

		if (
			objMDCControl instanceof checkbox.MDCCheckbox ||
			objMDCControl instanceof radio.MDCRadio
		) {

			if (objMDCControl.nativeControl_) {

				if (objMDCControl.nativeControl_.hasAttribute('aria-controls')) {

					const sErrorLabelId = objMDCControl.nativeControl_.getAttribute('aria-controls');

					if (sErrorLabelId) {

						const sErrorLabelElement = document.getElementById(sErrorLabelId);
						if (sErrorLabelElement) {

							sErrorLabelElement.innerText = sErrors;
							// sErrorLabelElement.classList.add('mdc-select-helper-text--persistent');

						}
					}
				}
			}

			return true;

		} else if (
			objMDCControl instanceof checkbox.MDCCheckbox ||
			objMDCControl instanceof tagify.MDCTagify ||
			objMDCControl instanceof textField.MDCTextField
		) {

			objMDCControl.valid = false;
			objMDCControl.helperTextContent = sErrors;

			return true;

		}

        return false;

    }

    setValid(objForm, sFormControlName) {

        // Get form control native element
        const arrFormControls = objForm.querySelectorAll('[name="' + sFormControlName + '"]');
        if (arrFormControls.length) {

            for (const objFormControl of arrFormControls) {

                // Check if we have a MDC wrapper around native control
                const objMDCControl = this.getMDCControlForFormControl(objFormControl);

                if (objMDCControl) {

                    this.setMDCControlValid(objMDCControl);
                    break;

                }
            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const arrMDCFormControls = objForm.querySelectorAll('[data-name="' + sFormControlName + '"]');

        if (arrMDCFormControls.length) {

            for (const objMDCFormControl of arrMDCFormControls) {

                const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

                if (objMDCControl) {

                    this.setMDCControlValid(objMDCControl);
                    break;

                }
            }

            return true;

        }

        return false;

    }

    setInvalid(objForm, sFormControlName, sErrors) {

        // Get form control native element
        const arrFormControls = objForm.querySelectorAll('[name="' + sFormControlName + '"]');
        if (arrFormControls.length) {

            for (const objFormControl of arrFormControls) {

                // Check if we have a MDC wrapper around native control
                const objMDCControl = this.getMDCControlForFormControl(objFormControl);

                if (objMDCControl) {

                    this.setMDCControlInvalid(objMDCControl, sErrors);
                    break;

                }
            }

            return true;

        }

        // Maybe we have completely custom form element without native controls
        const arrMDCFormControls = objForm.querySelectorAll('[data-name="' + sFormControlName + '"]');

        if (arrMDCFormControls.length) {

            for (const objMDCFormControl of arrMDCFormControls) {

                const objMDCControl = this.getMDCControlForElement(objMDCFormControl);

                if (objMDCControl) {

                    this.setMDCControlInvalid(objMDCControl, sErrors);
                    break;

                }
            }

            return true;

        }

        return false;

    }

    appendChipToSet(objChipSet, id, name) {

        // <div class="mdc-chip" role="row">
        let elChip = document.createElement('div');
        elChip.setAttribute('class', 'mdc-chip');
        elChip.setAttribute('role', 'row');
        elChip.setAttribute('data-id', id);

        // <div class="mdc-chip__ripple"></div>
        let elChipRipple = document.createElement('div');
        elChipRipple.setAttribute('class', 'mdc-chip__ripple');

        elChip.appendChild(elChipRipple);

        // <span class="mdc-chip__checkmark" >
        let elChipCheckmark = document.createElement('span');
        elChipCheckmark.setAttribute('class', 'mdc-chip__checkmark');

        // <svg class="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
        let elChipCheckmarkSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elChipCheckmarkSvg.setAttribute('class', 'mdc-chip__checkmark-svg');
        elChipCheckmarkSvg.setAttribute('viewBox', '-2 -3 30 30');

        // <path class="mdc-chip__checkmark-path" fill="none" stroke="black" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
        let elChipCheckmarkSvgPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        elChipCheckmarkSvgPath.setAttribute('class', 'mdc-chip__checkmark-path');
        elChipCheckmarkSvgPath.setAttribute('fill', 'none');
        elChipCheckmarkSvgPath.setAttribute('stroke', 'black');
        elChipCheckmarkSvgPath.setAttribute('d', 'M1.73,12.91 8.1,19.28 22.79,4.59');

        elChipCheckmarkSvg.appendChild(elChipCheckmarkSvgPath);
        elChipCheckmark.appendChild(elChipCheckmarkSvg);
        elChip.appendChild(elChipCheckmark);

        // <span role="gridcell">
        let elGridCell = document.createElement('span');
        elGridCell.setAttribute('role', 'gridcell');

        // <span role="checkbox" tabindex="0" aria-checked="false" class="mdc-chip__primary-action">
        let elCheckBox = document.createElement('span');
        elCheckBox.setAttribute('role', 'checkbox');
        elCheckBox.setAttribute('tabindex', '0');
        elCheckBox.setAttribute('aria-checked', 'false');
        elCheckBox.setAttribute('class', 'mdc-chip__primary-action');

        // <span class="mdc-chip__text">Filterable content</span>
        let elChipText = document.createElement('span');
        elChipText.setAttribute('class', 'mdc-chip__text');
        elChipText.textContent = name;

        elCheckBox.appendChild(elChipText);
        elGridCell.appendChild(elCheckBox);

        elChip.appendChild(elGridCell);

        objChipSet.root.appendChild(elChip);
        objChipSet.addChip(elChip);

    }

    createCircularProgress(mode = 'indeterminate', style = 'large') {

        let cl = 'mdc-circular-progress--large';
        let vb = '0 0 48 48';
        let cx = '24';
        let cy = '24';
        let r = '18';
        let sa = '113.097';
        let so = '56.549';

        switch (style) {

            case 'small':
                cl = 'mdc-circular-progress--small';
                vb = '0 0 24 24';
                cx = '12';
                cy = '12';
                r = '8.75';
                sa = '54.978';
                so = '27.489';
                break;

            case 'medium':
                cl = 'mdc-circular-progress--medium';
                vb = '0 0 32 32';
                cx = '16';
                cy = '16';
                r = '12.5';
                sa = '78.54';
                so = '39.27';
                break;

        }

        if (mode === 'indeterminate') {

            cl += ' mdc-circular-progress--indeterminate';

        }

        const elCircularProgress = document.createElement('div');
        elCircularProgress.setAttribute('class', 'mdc-circular-progress ' + cl);
        elCircularProgress.setAttribute('role', 'progressbar');
        elCircularProgress.setAttribute('aria-label', 'Uploading...');
        elCircularProgress.setAttribute('aria-valuemin', '0');
        elCircularProgress.setAttribute('aria-valuemax', '1');
        elCircularProgress.setAttribute('data-mdc-auto-init', 'MDCCircularProgress');

        // Determinate container
        const elCircularProgressDeterminateContainer = document.createElement('div');
        elCircularProgressDeterminateContainer.setAttribute('class', 'mdc-circular-progress__determinate-container');

        const elCircularProgressGraphic = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elCircularProgressGraphic.setAttribute('class', 'mdc-circular-progress__determinate-circle-graphic');
        elCircularProgressGraphic.setAttribute('viewBox', vb);

        const elCircularProgressGraphicSvgCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        elCircularProgressGraphicSvgCircle.setAttribute('class', 'mdc-circular-progress__determinate-circle');
        elCircularProgressGraphicSvgCircle.setAttribute('cx', cx);
        elCircularProgressGraphicSvgCircle.setAttribute('cy', cy);
        elCircularProgressGraphicSvgCircle.setAttribute('r', r);
        elCircularProgressGraphicSvgCircle.setAttribute('stroke-dasharray', sa);
        elCircularProgressGraphicSvgCircle.setAttribute('stroke-dashoffset', sa);

        elCircularProgressGraphic.appendChild(elCircularProgressGraphicSvgCircle);
        elCircularProgressDeterminateContainer.appendChild(elCircularProgressGraphic);
        elCircularProgress.appendChild(elCircularProgressDeterminateContainer);

        // Indeterminate container
        const elCircularProgressIndeterminateContainer = document.createElement('div');
        elCircularProgressIndeterminateContainer.setAttribute('class', 'mdc-circular-progress__indeterminate-container');

        const elCircularProgressSpinnerLayer = document.createElement('div');
        elCircularProgressSpinnerLayer.setAttribute('class', 'mdc-circular-progress__spinner-layer');

        // Clipper left
        const elCircularProgressClipperLeft = document.createElement('div');
        elCircularProgressClipperLeft.setAttribute('class', 'mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left');

        const elCircularProgressClipperLeftSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elCircularProgressClipperLeftSvg.setAttribute('class', 'mdc-circular-progress__indeterminate-circle-graphic');
        elCircularProgressClipperLeftSvg.setAttribute('viewBox', vb);

        const elCircularProgressClipperLeftSvgCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        elCircularProgressClipperLeftSvgCircle.setAttribute('cx', cx);
        elCircularProgressClipperLeftSvgCircle.setAttribute('cy', cy);
        elCircularProgressClipperLeftSvgCircle.setAttribute('r', r);
        elCircularProgressClipperLeftSvgCircle.setAttribute('stroke-dasharray', sa);
        elCircularProgressClipperLeftSvgCircle.setAttribute('stroke-dashoffset', so);

        elCircularProgressClipperLeftSvg.appendChild(elCircularProgressClipperLeftSvgCircle);
        elCircularProgressClipperLeft.appendChild(elCircularProgressClipperLeftSvg);
        elCircularProgressSpinnerLayer.appendChild(elCircularProgressClipperLeft);

        // Gap
        const elCircularProgressGap = document.createElement('div');
        elCircularProgressGap.setAttribute('class', 'mdc-circular-progress__gap-patch');

        const elCircularProgressGapSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elCircularProgressGapSvg.setAttribute('class', 'mdc-circular-progress__indeterminate-circle-graphic');
        elCircularProgressGapSvg.setAttribute('viewBox', vb);

        const elCircularProgressGapSvgCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        elCircularProgressGapSvgCircle.setAttribute('cx', cx);
        elCircularProgressGapSvgCircle.setAttribute('cy', cy);
        elCircularProgressGapSvgCircle.setAttribute('r', r);
        elCircularProgressGapSvgCircle.setAttribute('stroke-dasharray', sa);
        elCircularProgressGapSvgCircle.setAttribute('stroke-dashoffset', so);

        elCircularProgressGapSvg.appendChild(elCircularProgressGapSvgCircle);
        elCircularProgressGap.appendChild(elCircularProgressGapSvg);
        elCircularProgressSpinnerLayer.appendChild(elCircularProgressGap);

        // Clipper right
        const elCircularProgressClipperRight = document.createElement('div');
        elCircularProgressClipperRight.setAttribute('class', 'mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right');

        const elCircularProgressClipperRightSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elCircularProgressClipperRightSvg.setAttribute('class', 'mdc-circular-progress__indeterminate-circle-graphic');
        elCircularProgressClipperRightSvg.setAttribute('viewBox', vb);

        const elCircularProgressClipperRightSvgCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        elCircularProgressClipperRightSvgCircle.setAttribute('cx', cx);
        elCircularProgressClipperRightSvgCircle.setAttribute('cy', cy);
        elCircularProgressClipperRightSvgCircle.setAttribute('r', r);
        elCircularProgressClipperRightSvgCircle.setAttribute('stroke-dasharray', sa);
        elCircularProgressClipperRightSvgCircle.setAttribute('stroke-dashoffset', so);

        elCircularProgressClipperRightSvg.appendChild(elCircularProgressClipperRightSvgCircle);
        elCircularProgressClipperRight.appendChild(elCircularProgressClipperRightSvg);
        elCircularProgressSpinnerLayer.appendChild(elCircularProgressClipperRight);


        elCircularProgressSpinnerLayer.appendChild(elCircularProgressClipperLeft);
        elCircularProgressSpinnerLayer.appendChild(elCircularProgressGap);
        elCircularProgressSpinnerLayer.appendChild(elCircularProgressClipperRight);

        elCircularProgressIndeterminateContainer.appendChild(elCircularProgressSpinnerLayer);
        elCircularProgress.appendChild(elCircularProgressIndeterminateContainer);

        return elCircularProgress;

    }

    buttonActionLoading(objButton) {

        objButton.setAttribute('disabled', 'disabled');

        const progressElement = this.createCircularProgress('indeterminate', 'small');
        if (progressElement) {

            const iconContainer = objButton.querySelector('.mdc-button__icon');
            if (iconContainer) {

                // Save old text content
                objButton.setAttribute('data-icon', iconContainer.textContent);
                iconContainer.textContent = '';
                iconContainer.appendChild(progressElement);

            }
        }
    }

    buttonActionLoadingSuccess(objButton) {

        const iconContainer = objButton.querySelector('.mdc-button__icon');
        if (iconContainer) {

            const progressElement = objButton.querySelector('.mdc-circular-progress');
            if (progressElement) {

                iconContainer.removeChild(progressElement);

            }

            iconContainer.textContent = objButton.getAttribute('data-icon');

        }

        objButton.removeAttribute('disabled');
    }
}
