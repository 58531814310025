/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUILinearProgressGlobal from './gb-ui-linear-progress-global';

export default class GBUIPartsList extends GBUIBase {

	constructor(objContainerElement, strUrl, iMarkId = null, iRelatedPartId = null) {

		super();

		this.objContainerElement = objContainerElement;
		if (this.objContainerElement) {

			this.objFilterElement = objContainerElement.querySelector('[data-ui-role="filter-element"]');

			if (this.objFilterElement) {

				this.objFilter = this.getMDCControlForFormControl(this.objFilterElement);

			}

			this.objListWrapperElement = objContainerElement.querySelector('[data-ui-role="list-wrapper"]');
			
		}

		this.iMarkId = iMarkId;
		this.strUrl = strUrl;
		this.iRelatedPartId = iRelatedPartId;

		this.objLoaderController = null;
		this.objProgressBar = new GBUILinearProgressGlobal();

		this.initEvents();

	}

	initEvents() {

		let that = this;

		if (this.objFilter === undefined) {

			return;

		}

		this.objFilter.listen('input', () => {

			that.loadNewPage(that.objFilter.value);

		});

		this.initPagination();

	}

	initPagination() {

		let that = this;

		if (this.objListWrapperElement === undefined) {

			return;

		}

		const arrPaginationButtons = this.objListWrapperElement.querySelectorAll('a[data-page-number]');
		const strClickHandlerCheckAttributeName = 'gb-event-listener-attached';
		const funcPaginationButtonClickHandler = function(objEvent) {

			const objTargetElement = objEvent.target || objEvent.srcElement;
			if (objTargetElement) {

				const iPageNumber = parseInt(objTargetElement.getAttribute('data-page-number')) || 1;

				that.loadNewPage(that.objFilter.value, iPageNumber, true);

			}

			objEvent.preventDefault();
			return false;

		};

		if (arrPaginationButtons) {

			for (let objPaginationButton of arrPaginationButtons) {

				const bEventListenerAttached = objPaginationButton.hasAttribute(strClickHandlerCheckAttributeName);

				if (!bEventListenerAttached) {

					objPaginationButton.addEventListener('click', funcPaginationButtonClickHandler, false);
					objPaginationButton.setAttribute(strClickHandlerCheckAttributeName, true);

				}
			}
		}
	}

	loadNewPage(strFilter = null, iPageNumber = 1, bScrollIntoView = false) {

		let that = this;

		// Show loader
		if (this.objProgressBar) {

			this.objProgressBar.open();

		}

		const strUrl = this.strUrl +'?'+ that.encodeQueryData({
			'filter': strFilter,
			'mark_id': this.iMarkId,
			'related_part_id': this.iRelatedPartId,
			'page': iPageNumber
		});

		// https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
		if (that.objLoaderController) {

			that.objLoaderController.abort();

		}
		that.objLoaderController = new AbortController();

		fetch(strUrl, {signal: that.objLoaderController.signal})
		.then(response => response ? response.json() : null)
		.then(response => {

			if (response.errors === undefined && response.html !== undefined) {

				that.objListWrapperElement.innerHTML = response.html;
				that.initPagination();

				if (bScrollIntoView) {

					that.objListWrapperElement.scrollIntoView({behavior: 'smooth', block: 'start'});

				}

				if (that.objProgressBar) {

					that.objProgressBar.close();

				}

			} else {

				// Some error
				if (that.objProgressBar) {

					that.objProgressBar.close();

				}
			}
		});
	}
}
