/*jshint esversion: 6 */

import {MDCLinearProgress} from '@material/linear-progress';
import GBUIBase from './../gb-ui-base';

export default class GBUILinearProgressGlobal extends GBUIBase {

	constructor() {

		super();

		this.initDOM();

	}

	initDOM() {

		const strSnackbarElementId = 'gb-ui-linear-progress-global';
		
		let objLinearProgressElement = document.getElementById(strSnackbarElementId);

		if (!objLinearProgressElement) {

			/*
			<div role="progressbar" class="mdc-linear-progress" aria-label="Example Progress Bar" aria-valuemin="0" aria-valuemax="1" aria-valuenow="0">
			  <div class="mdc-linear-progress__buffering-dots"></div>
			  <div class="mdc-linear-progress__buffer"></div>
			  <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
			    <span class="mdc-linear-progress__bar-inner"></span>
			  </div>
			  <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
			    <span class="mdc-linear-progress__bar-inner"></span>
			  </div>
			</div>
			*/
		
			objLinearProgressElement = document.createElement('div');
			objLinearProgressElement.setAttribute('role','progressbar');
			objLinearProgressElement.setAttribute('aria-valuemin','0');
			objLinearProgressElement.setAttribute('aria-valuemax','1');
			objLinearProgressElement.setAttribute('aria-valuenow','0');
			objLinearProgressElement.setAttribute('id',strSnackbarElementId);
			objLinearProgressElement.setAttribute('class','mdc-linear-progress mdc-linear-progress--indeterminate gb-linear-progress-global mdc-linear-progress--closed');
			objLinearProgressElement.setAttribute('data-mdc-auto-init','MDCLinearProgress');

			let objBufferingDotsElement = document.createElement('div');
			objBufferingDotsElement.setAttribute('class','mdc-linear-progress__buffering-dots');
			objLinearProgressElement.appendChild(objBufferingDotsElement);

			let objBufferElement = document.createElement('div');
			objBufferElement.setAttribute('class','mdc-linear-progress__buffer');
			objLinearProgressElement.appendChild(objBufferElement);

			let objPrimaryBarElement = document.createElement('div');
			objPrimaryBarElement.setAttribute('class','mdc-linear-progress__bar mdc-linear-progress__primary-bar');

			let objPrimaryBarInnerElement = document.createElement('span');
			objPrimaryBarInnerElement.setAttribute('class','mdc-linear-progress__bar-inner');
			objPrimaryBarElement.appendChild(objPrimaryBarInnerElement);
			
			objLinearProgressElement.appendChild(objPrimaryBarElement);

			let objSecondaryBarElement = document.createElement('div');
			objSecondaryBarElement.setAttribute('class','mdc-linear-progress__bar mdc-linear-progress__secondary-bar');

			let objSecondaryBarInnerElement = document.createElement('span');
			objSecondaryBarInnerElement.setAttribute('class','mdc-linear-progress__bar-inner');
			objSecondaryBarElement.appendChild(objSecondaryBarInnerElement);
			
			objLinearProgressElement.appendChild(objSecondaryBarElement);

			document.body.appendChild(objLinearProgressElement);

			global.gbui.objMDCInit.initElements(objLinearProgressElement);

		}

		if (objLinearProgressElement && objLinearProgressElement.MDCLinearProgress !== undefined) {

			this.objLinearProgress = objLinearProgressElement.MDCLinearProgress;

		}
	}

	open() {

		if (this.objLinearProgress !== undefined) {

			this.objLinearProgress.open();

		}
	}

	close() {
		
		if (this.objLinearProgress !== undefined) {
		
			this.objLinearProgress.close();

		}
	}
}
