/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

class GBUiMainMenuCartIcon extends GBUIBase {

	constructor() {

		super();
		this.objBadge = document.querySelector('[data-ui-role="main-menu-button-cart"] .mdc-button__badge');

	}

	full() {
	    if (this.objBadge && !this.objBadge.classList.contains('mdc-button__badge--visible')) {
	        this.objBadge.classList.add('mdc-button__badge--visible', 'mdc-button__badge--animated');
        }
    }

    empty() {
	    if (this.objBadge && this.objBadge.classList.contains('mdc-button__badge--visible')) {
	        this.objBadge.classList.remove('mdc-button__badge--visible', 'mdc-button__badge--animated');
        }
    }
}

export default new GBUiMainMenuCartIcon();
