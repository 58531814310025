/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
// import {MDCTabScroller} from '@material/tab-scroller';

export default class GBUITabs extends GBUIBase {

	constructor(objTabBarElement, objTabPagesElement, funcActivateEvent = null) {

		super();

		this.objTabBarElement = objTabBarElement !== undefined && objTabBarElement ? objTabBarElement : null;
		this.objTabPagesElement = objTabPagesElement !== undefined && objTabPagesElement ? objTabPagesElement : null;
		this.funcActivateEvent = funcActivateEvent;
		// this.tabPagesScroller = this.objTabPagesElement ? new MDCTabScroller(this.objTabPagesElement) : null;
		// if (this.tabPagesScroller) {

		// 	console.log(this.tabPagesScroller.getScrollContentWidth());

		// }

		this.initEvents();

	}

	getActiveTabIndex() {

		return this.objTabBarElement.MDCTabBar.getDefaultFoundation().adapter.getPreviousActiveTabIndex();

	}

	activateTab(index) {

		this.objTabBarElement.MDCTabBar.activateTab(index);

	}

	scrollIntoView(index) {

		this.objTabBarElement.MDCTabBar.scrollIntoView(index);

	}

	initEvents() {

		if (!this.objTabBarElement) return false;

		var that = this;

		let tabActivateHandler = function(event) {
			
			if (event===undefined || event.detail===undefined || event.detail.index===undefined) return;

			let page = that.objTabPagesElement.querySelector('[data-index="'+ event.detail.index +'"]');
			let pages = that.objTabPagesElement.querySelectorAll('.mdc-tab-page');

			if (!page || !pages) return;

			for(let j = 0; j < pages.length; j++) {

				pages[j].classList.add('hidden');

			}

			page.classList.remove('hidden');

			if (typeof that.funcActivateEvent === "function") {

				that.funcActivateEvent(that.getActiveTabIndex());

			}

			global.gbui.objMDCInit.initElements();

			// that.tabPagesScroller.scrollTo(event.detail.index * parseInt(that.tabPagesScroller.getScrollContentWidth()));

		};

		this.objTabBarElement.MDCTabBar.listen('MDCTabBar:activated', tabActivateHandler);

		return true;

	}
}