/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
// import GBUILinearProgressGlobal from './gb-ui-linear-progress-global';
import GBUISnackbar from './gb-ui-snackbar';

export default class GBUIMMGUnion extends GBUIBase {

	constructor(objForm, sMarkElementName, sModelElementName, sGenerationElementName, sBaseUrl, sFieldName = 'url') {

		super();

		this.sBaseUrl = sBaseUrl;
		this.sFieldName = sFieldName || 'url';
		this.sLoadingClassName = 'mdc-select--loading';
		this.sErrorMessage = 'An error occurred, please try again later.';

		this.objLoaderController = null;

		this.objForm = objForm;
		this.sMarkElementName = sMarkElementName;
		this.sModelElementName = sModelElementName;
		this.sGenerationElementName = sGenerationElementName;

		this.objMarkElement = this.getFormControlElement(objForm, sMarkElementName);
		this.objModelElement = this.getFormControlElement(objForm, sModelElementName);
		this.objGenerationElement = this.getFormControlElement(objForm, sGenerationElementName);

		this.objSnackbar = GBUISnackbar;

		this.initEvents();
		this.initControls();

	}

	showSnackbar(str = null) {

		if (this.objSnackbar && str) {

			this.objSnackbar.open(str);

		}
	}

	abortAjaxCalls() {
		
		if (this.objLoaderController) {

			this.objLoaderController.abort();

		}
		this.objLoaderController = new AbortController();

	}

	populateOptionsToSelect(strElementName, arrData = [], idColumn = 'id', nameColumn = 'name', urlColumn = 'url') {

		this.appendOptionToSelect(this.objForm, strElementName);

		for(let i = 0; i < arrData.length; i++) {

			if (arrData[i][urlColumn] !== undefined && arrData[i][nameColumn] !== undefined && arrData[i][this.sFieldName] !== undefined) {

				this.appendOptionToSelect(this.objForm, strElementName, arrData[i][this.sFieldName], arrData[i][nameColumn], {'data-url': arrData[i][urlColumn]});

			}
		}
	}

	markChangeHandler() {

		let that = this;

		that.setValue(that.objForm, that.sModelElementName);
		that.setValue(that.objForm, that.sGenerationElementName);
		that.removeAllOptionsFromSelect(that.objForm, that.sModelElementName);
		that.removeAllOptionsFromSelect(that.objForm, that.sGenerationElementName);

		const sMarkUrl = that.getOptionAttribute(that.objForm, that.sMarkElementName, 'data-url');

		if ( sMarkUrl ) {

			that.disableElement(that.objForm, that.sModelElementName);
			that.disableElement(that.objForm, that.sGenerationElementName);

			that.objModelElement.classList.add(that.sLoadingClassName);

			const sColumns = 'url,name'+ (that.sFieldName !== 'url' ? ','+ that.sFieldName : '');

			const sUrl = that.sBaseUrl + sMarkUrl +'/models/?columns='+ sColumns +'&sort=name';

			that.abortAjaxCalls();

			fetch(sUrl, {signal: that.objLoaderController.signal})
			.then(response => response ? response.json() : null)
			.then(response => {

				if (
					response.errors === undefined && 
					response.data !== undefined && 
					Array.isArray(response.data)
				) {

					that.populateOptionsToSelect(that.sModelElementName, response.data);
					
					that.objModelElement.classList.remove(that.sLoadingClassName);
					that.disableElement(that.objForm, that.sModelElementName, false);

				} else {

					// Show error
					// 

					that.objModelElement.classList.remove(that.sLoadingClassName);
					that.disableElement(that.objForm, that.sModelElementName, false);
					
					that.showSnackbar(that.sErrorMessage);

				}
			});
		}		
	}

	modelChangeHandler() {

		let that = this;

		that.setValue(that.objForm, that.sGenerationElementName);
		that.removeAllOptionsFromSelect(that.objForm, that.sGenerationElementName);

		const sMarkUrl = that.getOptionAttribute(that.objForm, that.sMarkElementName, 'data-url');
		const sModelUrl = that.getOptionAttribute(that.objForm, that.sModelElementName, 'data-url');

		if ( sMarkUrl && sModelUrl ) {

			that.disableElement(that.objForm, that.sGenerationElementName);
			that.objGenerationElement.classList.add(that.sLoadingClassName);

			const sColumns = 'url,name_full'+ (that.sFieldName !== 'url' ? ','+ that.sFieldName : '');

			const sUrl = that.sBaseUrl + sMarkUrl +'/'+ sModelUrl +'/generations/?columns='+ sColumns +'&sort=year_start&order=desc';

			that.abortAjaxCalls();

			fetch(sUrl, {signal: that.objLoaderController.signal})
			.then(response => response ? response.json() : null)
			.then(response => {

				if (
					response.errors === undefined && 
					response.data !== undefined && 
					Array.isArray(response.data)
				) {

					that.populateOptionsToSelect(that.sGenerationElementName, response.data, 'id', 'name_full');

					that.objGenerationElement.classList.remove(that.sLoadingClassName);
					that.disableElement(that.objForm, that.sGenerationElementName, false);

				} else {

					// Show error
					// 
					that.objGenerationElement.classList.remove(that.sLoadingClassName);
					that.disableElement(that.objForm, that.sGenerationElementName, false);

					that.showSnackbar(that.sErrorMessage);

				}
			});
		}
	}

	generationChangeHandler() {

		let that = this;

	}

	initEvents() {

		let that = this;

		const objMDCMarkControl = this.getMDCControlForFormControl(this.objMarkElement);
		if (objMDCMarkControl) {

			objMDCMarkControl.listen('MDCSelect:change', () => that.markChangeHandler());

		} else {

			this.objMarkElement.addEventListener('change', () => that.markChangeHandler());

		}

		const objMDCModelControl = this.getMDCControlForFormControl(this.objModelElement);
		if (objMDCModelControl) {

			objMDCModelControl.listen('MDCSelect:change', () => that.modelChangeHandler());

		} else {

			this.objModelElement.addEventListener('change', () => that.modelChangeHandler());

		}

		const objMDCGenerationControl = this.getMDCControlForFormControl(this.objGenerationElement);
		if (objMDCGenerationControl) {

			objMDCGenerationControl.listen('MDCSelect:change', () => that.generationChangeHandler());

		} else {

			this.objGenerationElement.addEventListener('change', () => that.generationChangeHandler());

		}
	}

	initControls() {

		const sMarkUrl = this.getOptionAttribute(this.objForm, this.sMarkElementName, 'data-url');
		const sModelUrl = this.getOptionAttribute(this.objForm, this.sModelElementName, 'data-url');

		this.disableElement(this.objForm, this.sModelElementName, !sMarkUrl);
		this.disableElement(this.objForm, this.sGenerationElementName, !sMarkUrl || !sModelUrl);

	}
}