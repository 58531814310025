/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUISnackbar from './gb-ui-snackbar';
import GBUICreateShoppingListDialog from './gb-ui-create-shopping-list-dialog';

export default class GbUiCartBase extends GBUIBase {

    constructor(arrUrls) {

        super();

        this.arrUrls = arrUrls;

        this.objSnackbar = GBUISnackbar;
        this.objCreateShoppingListDialog = GBUICreateShoppingListDialog;

    }

    getAddToShoppingListUrl(shoppingList) {

        return this.arrUrls.apiAddToShoppingList.replace('%shopping_list%', shoppingList);

    }

    getShoppingListUrl(shoppingList) {

        return this.arrUrls.shoppingList.replace('%shopping_list%', shoppingList);

    }

    disableCurrentListInMenu(objMenu, shoppingListId) {

    	const arrMenuItems = objMenu.querySelectorAll('.mdc-list-item[data-id]');
    	for(const objMenuItem of arrMenuItems) {

    		if (!objMenuItem.hasAttribute('data-id')) {
    			continue;
			}

    		const dataId = parseInt(objMenuItem.getAttribute('data-id'));
    		objMenuItem.classList.toggle('mdc-list-item--disabled', dataId === shoppingListId);

		}
	}

    addShoppingListToSideList(shoppingListName, shoppingListUrl) {

		const objSideList = document.getElementById('sidebar-shopping-lists');
		if (!objSideList) {

			return false;

		}

		// <a class="mdc-list-item" tabindex="0" role="option" data-mdc-auto-init="MDCRipple" href="" title="">
		// 	<span class="mdc-list-item__ripple"></span>
		// 	<span class="mdc-list-item__graphic i" aria-hidden="true">assignment</span>
		// 	<span class="mdc-list-item__text"></span>
		// </a>

		const objListElem = document.createElement('a');
		objListElem.setAttribute('class', 'mdc-list-item');
		objListElem.setAttribute('tabindex', '0');
		objListElem.setAttribute('role', 'option');
		objListElem.setAttribute('data-mdc-auto-init', 'MDCRipple');
		objListElem.setAttribute('href', this.getShoppingListUrl(shoppingListUrl));
		objListElem.setAttribute('title', shoppingListName);

		const objRipple = document.createElement('span');
		objRipple.setAttribute('class', 'mdc-list-item__ripple');

		const objListGraphic = document.createElement('span');
		objListGraphic.setAttribute('class', 'mdc-list-item__graphic i');
		objListGraphic.setAttribute('aria-hidden', 'true');
		objListGraphic.textContent = 'assignment';

		const objListText = document.createElement('span');
		objListText.classList.add('mdc-list-item__text');
		objListText.textContent = shoppingListName;

		objListElem.appendChild(objRipple);
		objListElem.appendChild(objListGraphic);
		objListElem.appendChild(objListText);

		const objFirstDivider = objSideList.querySelector('.mdc-list-divider');

		if (objFirstDivider.classList.contains('hidden')) {
			objFirstDivider.classList.remove('hidden');
		}

		objFirstDivider.after(objListElem);

		return true;

	}

	addShoppingListToMenu(objMenu, shoppingListName, shoppingListUrl, shoppingListId) {

		// <li class="mdc-list-item" role="menuitem" data-url="" data-id="">
		// 	<span class="mdc-list-item__ripple"></span>
		// 	<span class="mdc-list-item__text"></span>
		// </li>

		const objListElem = document.createElement('li');
		objListElem.setAttribute('class', 'mdc-list-item');
		objListElem.setAttribute('role', 'menuitem');
		objListElem.setAttribute('data-url', shoppingListUrl);
		objListElem.setAttribute('data-id', shoppingListId);

		const objRipple = document.createElement('span');
		objRipple.setAttribute('class', 'mdc-list-item__ripple');

		const objListText = document.createElement('span');
		objListText.classList.add('mdc-list-item__text');
		objListText.textContent = shoppingListName;

		objListElem.appendChild(objRipple);
		objListElem.appendChild(objListText);

		const objFirstDivider = objMenu.querySelector('.mdc-list-divider');

		if (objFirstDivider.classList.contains('hidden')) {
			objFirstDivider.classList.remove('hidden');
		}

		objFirstDivider.after(objListElem);

		return true;

	}

    getFormData() {

        let objFormData = {};

		for(const sFormControlName of this.arrFormControls) {

			objFormData[sFormControlName] = this.getValue(this.objContainer, sFormControlName);

		}

        return objFormData;
    }
}
