/*jshint esversion: 6 */

import {MDCDialog} from '@material/dialog';
import GBUIBase from './../gb-ui-base';

export default class GBUIDialog extends GBUIBase {

	constructor(objDialogElement, arrFormControls = [], funcOnOK = null, funcOnCancel = null) {

		super();

		var that = this;

		this.objDialogElement = objDialogElement;
		this.arrFormControls = arrFormControls;
		this.funcOnOK = funcOnOK;
		this.funcOnCancel = funcOnCancel;

		if (this.objDialogElement) {

			this.objDialog = new MDCDialog(this.objDialogElement);

			this.objDialog.listen('MDCDialog:closing', (objEvent) => {

				if (objEvent===undefined || objEvent.detail===undefined || objEvent.detail.action===undefined) {

					return false;

				}

				switch(objEvent.detail.action) {
					case "close": 
					case "cancel": 
						if (typeof that.funcOnCancel === "function") {

							that.funcOnCancel();

						}
					break;
				}
			} );

			let btnOKElement = this.objDialogElement.querySelector('[data-ui-role="button-ok"]');
			if (btnOKElement) {

				btnOKElement.addEventListener('click', function() {

					that.doOK();

				}, true);
			}
		}
	}

	showErrors(arrErrors, objFormElement = this.objDialogElement) {

		for(const sFormControlName of this.arrFormControls) {

			if (arrErrors[sFormControlName] !== undefined) {

				this.setInvalid(objFormElement, sFormControlName, Array.isArray(arrErrors[sFormControlName]) ? arrErrors[sFormControlName].join('. ') : arrErrors[sFormControlName]);

			} else {

				this.setValid(objFormElement, sFormControlName);

			}
		}
	}

	isOpen() {

		return this.objDialog.open;

	}

	open() {

		this.showErrors([]);
		this.objDialog.open();

	}

	close() {
		
		this.objDialog.close();

	}

	doOK() {

		if (typeof this.funcOnOK === "function") {

			this.funcOnOK();

		}
	}
}
