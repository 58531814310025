/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUIPopStateCatcher extends GBUIBase {

	constructor() {

		super();
		this.initEvents();

	}

	initEvents() {

		window.addEventListener('popstate', (ev) => this.onPopState(ev));
	}

	onPopState(ev) {

		if (ev && this.objHasProperty(ev, 'state')) {

			if (
				this.objHasProperty(ev.state, 'emitter') &&
				global.pushStateEmitters !== undefined &&
				this.objHasProperty(global.pushStateEmitters, ev.state.emitter)
			) {

				const emitter = global.pushStateEmitters[ev.state.emitter];

				switch(ev.state.emitter) {

					case 'gallery':

						const l = emitter.items.length;

						for(let i = 0; i < l; i++) {

							const item = emitter.items[i];

							if (item.url !== undefined && item.url === document.location.href) {

								emitter.ui.setDontPushState(true);
								emitter.goTo(i);
								emitter.ui.setDontPushState(false);
								return;

							}
						}

						if (document.location.href === emitter.ui.getInitialUrl()) {

							emitter.close();

						}

					break;

					default:
						
						window.location.reload(false);

					break;
				}

			} else {

				window.location.reload(false);

			}
		}

	}
}
