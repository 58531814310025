/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUIMe extends GBUIBase {

	constructor(url, cb) {

		super();
		
		this.makeRequest(url, 'GET', null, function(arrData, objErrors) {

			setTimeout(cb, 0, arrData, objErrors);

		});
	}
}
