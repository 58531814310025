/*jshint esversion: 6 */
// Based on https://github.com/ebidel/demos/blob/master/sticky-position-event.html

import GBUIBase from './../gb-ui-base';
// import {MDCTabScroller} from '@material/tab-scroller';

export default class GBUIStickyList extends GBUIBase {

	constructor(container, listElementsSelector) {

		super();

		this.container = container;
		this.listElementsSelector = listElementsSelector;

		if (window.IntersectionObserver) {

			this.init();

		}
	}

	addWatchers(className) {

		let that = this;

		return Array.from(that.container.querySelectorAll(that.listElementsSelector)).map(elem => {

			const watcher = document.createElement('div');
			watcher.classList.add('sticky-list__watcher', className);
			return elem.appendChild(watcher);

		});
	}

	/**
	* Dispatches a `sticky-event` custom event on the element.
	* @param {boolean} stuck
	* @param {!Element} target Target element of event.
	*/
	fire(stuck, target) {

		const evt = new CustomEvent('sticky-change', {detail: {stuck, target}});
		document.dispatchEvent(evt);

	}

	/**
	 * Sets up an intersection observer to notify when elements with the class
	 * `.sticky-list__watcher--top` become visible/invisible at the top of the container.
	 */
	observeHeaders() {

		let that = this;

		const observer = new IntersectionObserver((records, observer) => {

			for (const record of records) {

				const targetInfo = record.boundingClientRect;
				const stickyTarget = record.target.parentElement;
				const rootBoundsInfo = record.rootBounds;

				if (targetInfo.bottom < rootBoundsInfo.top) {

					that.fire(true, stickyTarget);

				}
				if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom) {

					that.fire(false, stickyTarget);

				}
			}
		}, {
			// rootMargin: '-16px',
			threshold: [0],
			root: null
		});

		// Add the bottom watchers to each section and attach an observer.
		const watchers = that.addWatchers('sticky-list__watcher--top');
		watchers.forEach(elem => observer.observe(elem));

	}

	/**
	 * Sets up an intersection observer to notify when elements with the class
	 * `.sticky-list__watcher--bottom` become visible/invisible at the botton of the
	 * container.
	 */
	observeFooters() {

		let that = this;

		const observer = new IntersectionObserver((records, observer) => {

			for (const record of records) {

				const targetInfo = record.boundingClientRect;
				const stickyTarget = record.target.parentElement;
				const rootBoundsInfo = record.rootBounds;
				// const headerControl = stickyTarget.querySelector('[aria-expanded][aria-controls]');
				// const areaExpanded = targetControl && targetControl.getAttribute('aria-expanded') ? targetControl.getAttribute('aria-expanded') : null;

				let headerControl = null;
				let headerIsVisible = false;

				for(let i = 0; i < stickyTarget.children.length; i++) {

					if (stickyTarget.children[i].classList.contains('sticky-list__watcher--top')) {

						headerControl = stickyTarget.children[i];
						break;

					}
				}

				if (headerControl) {

					const headerInfo = headerControl.getBoundingClientRect();

					if (headerInfo.bottom > rootBoundsInfo.top) {

						headerIsVisible = true;

					}
				}

				if (targetInfo.bottom < rootBoundsInfo.top) {
				
					that.fire(false, stickyTarget);

				}
				
				if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom && !headerIsVisible) {

					that.fire(true, stickyTarget);

				}
			}
		}, {
			// rootMargin: '16px',
			// Get callback slightly before element is 100% visible/invisible.
			threshold: [0],
			root: null
		});

		// Add the bottom watchers to each section and attach an observer.
		const watchers = that.addWatchers('sticky-list__watcher--bottom');
		watchers.forEach(elem => observer.observe(elem));

	}

	init() {

		this.observeHeaders();
		this.observeFooters();

	}

}