/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';

export default class GBUIUserEditProfile extends GBUIBase {

	constructor(sFormSelector) {

		super();

		var that = this;

		this.sFormSelector = sFormSelector;
		this.objFormElement = document.querySelector(sFormSelector);

		this.initEvents();

	}

	initControlWithEnabled(sName) {

		let that = this;

		const objElement = this.objFormElement.querySelector('[name="'+ sName +'"]');
		if (objElement) {

			const objEditEnablerElement = objElement.parentElement.querySelector('[data-ui-role="form-element-enabler"]');
			if (objEditEnablerElement) {

				objEditEnablerElement.addEventListener('click', function(objEvent) {

					const objMDCControl = that.getMDCControlForFormControl(objElement);

					objMDCControl.disabled = false;
					objMDCControl.focus();
					objMDCControl.getInputAdapterMethods_().getNativeInput().select();

					const objChangedElement = that.objFormElement.querySelector('[name="'+ sName +'-changed"]');

					if ( objChangedElement ) {

						objChangedElement.value = 1;
						
					}

					objEvent.preventDefault();
					objEvent.stopPropagation();

					return false;

				} );
			}
		}
	}

	initEvents() {

		this.initControlWithEnabled('email');
		this.initControlWithEnabled('password');

	}
}
