/*jshint esversion: 6 */

import GBUIBase from './../gb-ui-base';
import GBUIDialog from './../components/gb-ui-dialog';

class GBUICreateShoppingListDialog extends GBUIBase {

	constructor() {

		super();

		let that = this;

		this.formControls = [
			'name',
			'description',
		];

		this.objDialogElement = document.getElementById('create-shopping-list-dialog');

		if (this.objDialogElement) {

			this.objDialog = new GBUIDialog(this.objDialogElement, this.formControls, () => {

				if (!that.addUrl || !(that.cb instanceof Function)) {

					return;

				}

				// Send the info
				// 
				let objFormData = {};

				for(const sFormControlName of that.formControls) {

					const value = that.getValue(that.objDialogElement, sFormControlName);
					if (value) {

						objFormData[sFormControlName] = value;

					}
				}

				that.makeRequest(that.addUrl, 'PUT', objFormData, function(arrData, objErrors) {

					if (objErrors === null) {

						setTimeout(() => that.cb(arrData), 0);
						that.objDialog.close();

					} else {

						that.objDialog.showErrors(objErrors);

					}
				});

			}, () => setTimeout(() => that.cb(null), 0));
		}
	}

	open(addUrl, cb) {

		if (!this.objDialog) {

			return;

		}

		this.addUrl = addUrl;
		this.cb = cb;

		this.objDialog.open();

	}
}

export default new GBUICreateShoppingListDialog();
